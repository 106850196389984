// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import { Moment } from 'moment';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class ProgramService {
    async getPrograms(
        programId: string,
        programName: string,
        programCreatedAtStart: Moment | null,
        programCreatedAtEnd: Moment | null,
        programUpdatedAtStart: Moment | null,
        programUpdatedAtEnd: Moment | null,
        userId: string,
        userUsername: string,
        userName: string,
        userEmail: string,
        page: number,
        rowsPerPage: number,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.program.getPrograms,
                {
                    filterData: {
                        id: programId.length === 0 ? null : (programId as unknown) as number,
                        name: programName.length === 0 ? null : programName,
                        createdAtStart: programCreatedAtStart === null ? null : programCreatedAtStart.toISOString(),
                        createdAtEnd: programCreatedAtEnd === null ? null : programCreatedAtEnd.toISOString(),
                        updatedAtStart: programUpdatedAtStart === null ? null : programUpdatedAtStart.toISOString(),
                        updatedAtEnd: programUpdatedAtEnd === null ? null : programUpdatedAtEnd.toISOString(),
                        userId: userId.length === 0 ? null : (userId as unknown) as number,
                        userUsername: userUsername.length === 0 ? null : userUsername,
                        userName: userName.length === 0 ? null : userName,
                        userEmail: userEmail.length === 0 ? null : userEmail,
                    },
                    orderBy: 'name_asc',
                    pagination: {
                        currentPage: page + 1,
                        dataPerPage: rowsPerPage,
                    },

                    // 'id_asc'
                    // 'id_desc'
                    // 'createdAt_asc'
                    // 'createdAt_desc'
                    // 'updatedAt_asc'
                    // 'updatedAt_desc'
                    // 'name_asc'
                    // 'name_desc'
                    // 'userId_asc'
                    // 'userId_desc'
                    // 'userUsername_asc'
                    // 'userUsername_desc'
                    // 'userEmail_asc'
                    // 'userEmail_desc'
                    // 'userName_asc'
                    // 'userName_desc'
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async addProgram(programName: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.program.addProgram,
                {
                    name: programName,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async updateProgram(programId: number, programName: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .put(
                getAppApiUrlBase() + getAppApiRoutes.program.updateProgram.replace('{id}', (programId as unknown) as string),
                {
                    name: programName,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async removeProgram(programId: number): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .delete(
                getAppApiUrlBase() + getAppApiRoutes.program.removeProgram.replace('{id}', (programId as unknown) as string),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }
}

// -----------------------------------------------------------------Exports---
export default new ProgramService();
