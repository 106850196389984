// -----------------------------------------------------------------Imports---
import {
    Box,
    Divider,
    Grid,
    Typography,
} from '@mui/material';

import {
    BoxStyles,
    GridStyles,
    TypographyStyles,
} from './StatisticsCard.style';

// -----------------------------------------------------------------Exports---
export default function StatisticsCard(props: { title: string, subTitle?: string, data: number, }): JSX.Element {
    return (
        <Box style={BoxStyles.main}>
            <Grid container>
                <Grid item style={GridStyles.item} xs={6}>
                    <Typography style={TypographyStyles.data}>
                        {props.data.toLocaleString()}
                    </Typography>
                </Grid>
                <Grid item style={GridStyles.item} xs={6}>
                    <Typography style={TypographyStyles.subTitle}>
                        {props.subTitle}
                    </Typography>
                </Grid>
            </Grid>

            <Divider />

            <Typography style={TypographyStyles.title}>
                {props.title}
            </Typography>
        </Box>
    );
}
