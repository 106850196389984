// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    createRef,
    Dispatch,
    KeyboardEvent,
    RefObject,
    useEffect,
    useReducer,
} from 'react';

import {
    NavigateFunction,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    CircularProgress,
    Divider,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';

import {
    BoxStyles,
    BoxSx,
    ButtonStyles,
    CardMediaStyles,
    CardStyles,
    DividerStyles,
    Images,
    ImageStyles,
    LinkStyles,
    GridStyles,
} from './RegistrationPage.style';

import {
    nameOf,
    setReducer,
} from '../../CodeKit';

import {
    getAppRoutes,
    ReducerAction,
} from '../../Global';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';

import UserService from '../../services/user/UserService';

// ----------------------------------------------------------------Privates---
const inputIds: any = {
    username: 'inputUsername' as string,
    email: 'inputEmail' as string,
    name: 'inputName' as string,
    password: 'inputPassword' as string,
    passwordConfirmation: 'inputPasswordConfirmation' as string,
}

type PageModel = {
    backdropOpen: boolean;
    inputUsername: string;
    inputEmail: string;
    inputName: string;
    inputPassword: string;
    inputPasswordConfirmation: string;
    inputUsernameError: string;
    inputEmailError: string;
    inputNameError: string;
    inputPasswordError: string;
    inputPasswordConfirmationError: string;
}

const initialState: PageModel = {
    backdropOpen: false,
    inputUsername: '',
    inputEmail: '',
    inputName: '',
    inputPassword: '',
    inputPasswordConfirmation: '',
    inputUsernameError: '',
    inputEmailError: '',
    inputNameError: '',
    inputPasswordError: '',
    inputPasswordConfirmationError: '',
}

// -----------------------------------------------------------------Exports---
export default function RegistrationPage(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();

    const inputEmailRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    const inputNameRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    const inputPasswordRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();
    const inputPasswordConfirmationRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    useEffect(function (): void {
        checkingTheMatchingOfPasswords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.inputPassword, state.inputPasswordConfirmation]);

    // -----------------------------------------------------------Functions---
    function cleaningOfInputErrors(): void {
        dispatch({
            field: nameOf<PageModel>('inputUsernameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputEmailError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputNameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputPasswordError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputPasswordConfirmationError'),
            payload: '',
        });
    }

    function checkingTheMatchingOfPasswords(): void {
        if (state.inputPassword !== state.inputPasswordConfirmation) {
            dispatch({
                field: nameOf<PageModel>('inputPasswordError'),
                payload: 'A "Jelszó" és "Jelszó megerősítés" mezők nem egyeznek.',
            });
            dispatch({
                field: nameOf<PageModel>('inputPasswordConfirmationError'),
                payload: 'A "Jelszó" és "Jelszó megerősítés" mezők nem egyeznek.',
            });
        }
        else {
            dispatch({
                field: nameOf<PageModel>('inputPasswordError'),
                payload: '',
            });
            dispatch({
                field: nameOf<PageModel>('inputPasswordConfirmationError'),
                payload: '',
            });
        }
    }

    // --------------------------------------------------------------Events---
    function onChangeInputUsername(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputUsernameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputUsername'),
            payload: event.target.value,
        });
    }

    function onChangeInputEmail(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputEmailError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputEmail'),
            payload: event.target.value,
        });
    }

    function onChangeInputName(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputNameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputName'),
            payload: event.target.value,
        });
    }

    function onChangeInputPassword(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputPassword'),
            payload: event.target.value,
        });
    }

    function onChangeInputPasswordConfirmation(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputPasswordConfirmation'),
            payload: event.target.value,
        });
    }

    function onKeyPressInput(event: KeyboardEvent<HTMLImageElement>): void {
        if (event.key !== 'Enter') {
            return;
        }

        switch ((event.target as HTMLInputElement).id) {
            case inputIds.username: {
                const inputs: HTMLInputElement[] = Array.from(inputEmailRef.current!.querySelectorAll('input')) || [];
                inputs[0] && inputs[0].focus();
                break;
            }
            case inputIds.email: {
                const inputs: HTMLInputElement[] = Array.from(inputNameRef.current!.querySelectorAll('input')) || [];
                inputs[0] && inputs[0].focus();
                break;
            }
            case inputIds.name: {
                const inputs: HTMLInputElement[] = Array.from(inputPasswordRef.current!.querySelectorAll('input')) || [];
                inputs[0] && inputs[0].focus();
                break;
            }
            case inputIds.password: {
                const inputs: HTMLInputElement[] = Array.from(inputPasswordConfirmationRef.current!.querySelectorAll('input')) || [];
                inputs[0] && inputs[0].focus();
                break;
            }
            case inputIds.passwordConfirmation: {
                onClickRegistration();
                break;
            }
        }
    }

    async function onClickRegistration(): Promise<void> {
        cleaningOfInputErrors();

        checkingTheMatchingOfPasswords();

        if (state.inputPasswordConfirmationError.length !== 0) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await UserService.addUser(state.inputUsername, state.inputEmail, state.inputName, state.inputPassword);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success('Sikeres regisztráció.');
            navigate(getAppRoutes.login);
        }
        else {
            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    switch (field.field!) {
                        case 'Username': {
                            dispatch({
                                field: nameOf<PageModel>('inputUsernameError'),
                                payload: field.message!,
                            });
                            break;
                        }
                        case 'Email': {
                            dispatch({
                                field: nameOf<PageModel>('inputEmailError'),
                                payload: field.message!,
                            });
                            break;
                        }
                        case 'Name': {
                            dispatch({
                                field: nameOf<PageModel>('inputNameError'),
                                payload: field.message!,
                            });
                            break;
                        }
                        case 'Password': {
                            dispatch({
                                field: nameOf<PageModel>('inputPasswordError'),
                                payload: field.message!,
                            });
                            break;
                        }
                    }
                    return undefined;
                });
            }

            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);
        }
    }

    function onClickBackdropToggle(): void {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: !state.backdropOpen,
        });
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <Box style={BoxStyles.main} sx={BoxSx().main}>
                <Card style={CardStyles.main}>
                    <CardMedia alt="productLogo" component="img" image={Images.productLogoWithIcon} style={CardMediaStyles.header} />
                    <Grid container style={GridStyles.main}>
                        <Grid item style={GridStyles.left}>
                            <CardHeader title="Regisztráció" />
                        </Grid>
                        <Grid item style={GridStyles.right}>
                            <Link href="/login" style={LinkStyles().main}>
                                Van már fiókod?
                            </Link>
                        </Grid>
                    </Grid>
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography>
                                Felhasználónév
                            </Typography>
                            <TextField autoFocus id={inputIds.username} error={state.inputUsernameError.length !== 0} helperText={state.inputUsernameError} hiddenLabel onChange={onChangeInputUsername} onKeyPress={onKeyPressInput} placeholder="felhasznalonev123" type="text" value={state.inputUsername} />
                            <Typography>
                                E-mail cím
                            </Typography>
                            <TextField id={inputIds.email} error={state.inputEmailError.length !== 0} helperText={state.inputEmailError} hiddenLabel onChange={onChangeInputEmail} onKeyPress={onKeyPressInput} placeholder="email@domain.com" ref={inputEmailRef} type="email" value={state.inputEmail} />
                            <Typography>
                                Név
                            </Typography>
                            <TextField id={inputIds.name} error={state.inputNameError.length !== 0} helperText={state.inputNameError} hiddenLabel onChange={onChangeInputName} onKeyPress={onKeyPressInput} placeholder="Teljes Név" ref={inputNameRef} type="text" value={state.inputName} />
                            <Typography>
                                Jelszó
                            </Typography>
                            <TextField id={inputIds.password} error={state.inputPasswordError.length !== 0} helperText={state.inputPasswordError} hiddenLabel onChange={onChangeInputPassword} onKeyPress={onKeyPressInput} placeholder="******" ref={inputPasswordRef} type="password" value={state.inputPassword} />
                            <Typography>
                                Jelszó megerősítés
                            </Typography>
                            <TextField id={inputIds.passwordConfirmation} error={state.inputPasswordConfirmationError.length !== 0} helperText={state.inputPasswordConfirmationError} hiddenLabel onChange={onChangeInputPasswordConfirmation} onKeyPress={onKeyPressInput} placeholder="******" ref={inputPasswordConfirmationRef} type="password" value={state.inputPasswordConfirmation} />
                            <Button color="info" onClick={onClickRegistration} style={ButtonStyles.registration} type="submit" variant="contained">
                                Regisztráció
                            </Button>
                            <Divider style={DividerStyles.footer} variant="middle" />
                            <Link href="https://szoftweb.com/" style={LinkStyles().footer} target="_blank">
                                <Box alt="companyLogo" component="img" src={Images.companyLogo} style={ImageStyles.footer} />
                            </Link>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>

            <Backdrop onClick={onClickBackdropToggle} open={state.backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </>
    );
}
