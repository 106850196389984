// -----------------------------------------------------------------Imports---
import { Slide } from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';

import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

// ----------------------------------------------------------------Privates---
const DialogTransition: ForwardRefExoticComponent<TransitionProps & { children: ReactElement<any, any>; } & RefAttributes<unknown>> = forwardRef(function Transition(props: TransitionProps & { children: ReactElement<any, any>; }, ref: Ref<unknown>): JSX.Element {
    return <Slide direction="up" ref={ref} {...props} />;
});

// -----------------------------------------------------------------Exports---
export default DialogTransition;
