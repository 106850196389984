// -----------------------------------------------------------------Imports---
import { Theme } from '@emotion/react';
import { createTheme } from '@mui/material';

// -----------------------------------------------------------------Exports---
export const GlobalTheme: Theme = createTheme({
    palette: {
        mode: 'dark',
    },
});
