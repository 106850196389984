// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    ChangeEvent,
    Dispatch,
    useEffect,
    useReducer,
} from 'react';

import {
    NavigateFunction,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    Avatar,
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Grid,
    TextField,
} from '@mui/material';

import {
    AvatarStyles,
    BoxStyles,
    BoxSx,
    ButtonStyles,
    CardStyles,
    DividerStyles,
    GridStyles,
    TextFieldStyles,
} from './AccountPage.style';

import {
    nameOf,
    setReducer,
} from '../../CodeKit';

import {
    dateTimeFormat,
    getAppRoutes,
    ReducerAction,
} from '../../Global';

import UserEntity from '../../entities/UserEntity';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';

import UserService from '../../services/user/UserService';

// ----------------------------------------------------------------Privates---
type PageModel = {
    user: UserEntity;
    backdropOpen: boolean;
    updatePage: boolean;
    inputName: string;
    inputUsername: string;
    inputEmail: string;
    inputPassword: string;
    inputPasswordConfirmation: string;
    inputOriginalPassword: string;
    inputNameError: string;
    inputUsernameError: string;
    inputEmailError: string;
    inputPasswordError: string;
    inputPasswordConfirmationError: string;
    inputOriginalPasswordError: string;
}

const initialState: PageModel = {
    user: {
        name: '',
        username: '',
        email: '',
    } as UserEntity,
    backdropOpen: false,
    updatePage: false,
    inputName: '',
    inputUsername: '',
    inputEmail: '',
    inputPassword: '',
    inputPasswordConfirmation: '',
    inputOriginalPassword: '',
    inputNameError: '',
    inputUsernameError: '',
    inputEmailError: '',
    inputPasswordError: '',
    inputPasswordConfirmationError: '',
    inputOriginalPasswordError: '',
}

// -----------------------------------------------------------------Exports---
export default function AccountPage(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();
    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    useEffect(function (): void {
        async function GetUser(): Promise<void> {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: true,
            });

            const response: ResponseModel = await UserService.getUser((localStorage.getItem('id')! as unknown) as number);

            if (response.status === 200) {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: false,
                });
                dispatch({
                    field: nameOf<PageModel>('user'),
                    payload: response.data as UserEntity[],
                });
                dispatch({
                    field: nameOf<PageModel>('inputName'),
                    payload: ((response.data as UserEntity[]) as UserEntity)!.name,
                });
                dispatch({
                    field: nameOf<PageModel>('inputUsername'),
                    payload: ((response.data as UserEntity[]) as UserEntity)!.username,
                });
                dispatch({
                    field: nameOf<PageModel>('inputEmail'),
                    payload: ((response.data as UserEntity[]) as UserEntity)!.email,
                });
            }
            else if (response.status === 401) {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: false,
                });
                toast.error('Sikertelen autentikáció.');
            }
            else {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: false,
                });

                toast.error(response.error!.message!);

                if (response.error!.fields !== null) {
                    (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        GetUser();
    }, [state.updatePage]);

    useEffect(function (): void {
        checkingTheMatchingOfPasswords();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.inputPassword, state.inputPasswordConfirmation]);

    useEffect(function (): void {
        dispatch({
            field: nameOf<PageModel>('inputOriginalPasswordError'),
            payload: state.inputOriginalPassword.length === 0 ? 'A mező kitöltése kötelező.' : '',
        });
    }, [state.inputOriginalPassword]);

    // -----------------------------------------------------------Functions---
    function cleaningOfInputErrors(): void {
        dispatch({
            field: nameOf<PageModel>('inputUsernameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputEmailError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputNameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputPasswordError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputPasswordConfirmationError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputOriginalPasswordError'),
            payload: state.inputOriginalPassword.length === 0 ? 'A mező kitöltése kötelező.' : '',
        });
    }

    function checkingTheMatchingOfPasswords(): void {
        if (state.inputPassword !== state.inputPasswordConfirmation) {
            dispatch({
                field: nameOf<PageModel>('inputPasswordError'),
                payload: 'A "Jelszó" és "Jelszó megerősítés" mezők nem egyeznek.',
            });
            dispatch({
                field: nameOf<PageModel>('inputPasswordConfirmationError'),
                payload: 'A "Jelszó" és "Jelszó megerősítés" mezők nem egyeznek.',
            });
        }
        else {
            dispatch({
                field: nameOf<PageModel>('inputPasswordError'),
                payload: '',
            });
            dispatch({
                field: nameOf<PageModel>('inputPasswordConfirmationError'),
                payload: '',
            });
        }
    }

    // --------------------------------------------------------------Events---
    function onChangeInputName(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputNameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputName'),
            payload: event.target.value,
        });
    }

    function onChangeInputUsername(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputUsernameError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputUsername'),
            payload: event.target.value,
        });
    }

    function onChangeInputEmail(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputEmailError'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('inputEmail'),
            payload: event.target.value,
        });
    }

    function onChangeInputPassword(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputPassword'),
            payload: event.target.value,
        });
    }

    function onChangeInputPasswordConfirmation(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputPasswordConfirmation'),
            payload: event.target.value,
        });
    }

    function onChangeInputOriginalPassword(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputOriginalPassword'),
            payload: event.target.value,
        });
    }

    async function onClickSave(): Promise<void> {
        cleaningOfInputErrors();

        checkingTheMatchingOfPasswords();

        if ((state.inputPasswordConfirmationError.length !== 0) || (state.inputOriginalPasswordError.length !== 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await UserService.updateUser(
            state.user!.id!,
            state.inputName,
            state.inputUsername,
            state.inputEmail,
            state.inputPassword,
            state.inputOriginalPassword,
        );

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('inputPassword'),
                payload: '',
            });
            dispatch({
                field: nameOf<PageModel>('inputPasswordConfirmation'),
                payload: '',
            });
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success('Sikeres mentés.');
            dispatch({
                field: nameOf<PageModel>('updatePage'),
                payload: !state.updatePage,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }
    }

    async function onClickRemove(): Promise<void> {
        if (state.inputOriginalPasswordError.length !== 0) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await UserService.removeUser(state.user!.id!, state.inputOriginalPassword);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success((response.data! as unknown) as string);
            navigate(getAppRoutes.login);
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }
    }

    function onClickBackdropToggle(): void {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: !state.backdropOpen,
        });
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <Box style={BoxStyles.main} sx={BoxSx().main}>
                <Avatar alt={state.user!.name!} src="/" style={AvatarStyles.main} />
                <Card>
                    <CardContent style={CardStyles.content}>
                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Név</Grid>
                            <Grid item xs={7}>
                                <TextField error={state.inputNameError.length !== 0} helperText={state.inputNameError} hiddenLabel onChange={onChangeInputName} placeholder="Teljes Név" style={TextFieldStyles.data} type="text" value={state.inputName} />
                            </Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Felhasználónév</Grid>
                            <Grid item xs={7}>
                                <TextField error={state.inputUsernameError.length !== 0} helperText={state.inputUsernameError} hiddenLabel onChange={onChangeInputUsername} placeholder="felhasznalonev123" style={TextFieldStyles.data} type="text" value={state.inputUsername} />
                            </Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>E-mail</Grid>
                            <Grid item xs={7}>
                                <TextField error={state.inputEmailError.length !== 0} helperText={state.inputEmailError} hiddenLabel onChange={onChangeInputEmail} placeholder="email@domain.com" style={TextFieldStyles.data} type="email" value={state.inputEmail} />
                            </Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Jelszó</Grid>
                            <Grid item xs={7}>
                                <TextField error={state.inputPasswordError.length !== 0} helperText={state.inputPasswordError} hiddenLabel onChange={onChangeInputPassword} placeholder="******" style={TextFieldStyles.data} type="password" value={state.inputPassword} />
                            </Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Jelszó megerősítés</Grid>
                            <Grid item xs={7}>
                                <TextField error={state.inputPasswordConfirmationError.length !== 0} helperText={state.inputPasswordConfirmationError} hiddenLabel onChange={onChangeInputPasswordConfirmation} placeholder="******" style={TextFieldStyles.data} type="password" value={state.inputPasswordConfirmation} />
                            </Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Eredeti jelszó</Grid>
                            <Grid item xs={7}>
                                <TextField error={state.inputOriginalPasswordError.length !== 0} helperText={state.inputOriginalPasswordError} hiddenLabel onChange={onChangeInputOriginalPassword} placeholder="******" style={TextFieldStyles.data} type="password" value={state.inputOriginalPassword} />
                            </Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Létrehozva</Grid>
                            <Grid item xs={7}>{moment(state.user!.createdAt).format(dateTimeFormat)}</Grid>
                        </Grid>

                        <Divider style={DividerStyles.main} />

                        <Grid container spacing={1}>
                            <Grid item style={GridStyles.titleColumn} xs={5}>Módosítva</Grid>
                            <Grid item xs={7}>{moment(state.user!.updatedAt).format(dateTimeFormat)}</Grid>
                        </Grid>

                        <Box style={BoxStyles.button}>
                            <Button color="error" onClick={onClickRemove} style={ButtonStyles.button} type="submit" variant="contained">
                                Törlés
                            </Button>

                            {(
                                state.inputName !== state.user!.name ||
                                state.inputUsername !== state.user!.username ||
                                state.inputEmail !== state.user!.email ||
                                (
                                    state.inputPassword.length !== 0 &&
                                    state.inputPasswordConfirmation.length !== 0 &&
                                    state.inputPassword === state.inputPasswordConfirmation
                                )
                            ) &&
                                <Button color="info" onClick={onClickSave} style={ButtonStyles.button} type="submit" variant="contained">
                                    Mentés
                                </Button>}
                        </Box>
                    </CardContent>
                </Card>
            </Box>

            <Backdrop onClick={onClickBackdropToggle} open={state.backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </>
    );
}
