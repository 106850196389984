// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import { Moment } from 'moment';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class NotificationSettingService {
    async getNotificationSettings(
        notificationSettingId: string,
        notificationSettingCreatedAtStart: Moment | null,
        notificationSettingCreatedAtEnd: Moment | null,
        notificationSettingUpdatedAtStart: Moment | null,
        notificationSettingUpdatedAtEnd: Moment | null,
        programId: string,
        logLevelId: string,
        userUsername: string,
        userEmail: string,
        userName: string,
        programName: string,
        logLevelName: string,
        logLevelValue: string,
        notificationSettingIsNotify: string,
        page: number,
        rowsPerPage: number,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.notificationSetting.getNotificationSettings,
                {
                    filterData: {
                        id: notificationSettingId.length === 0 ? null : (notificationSettingId as unknown) as number,
                        createdAtStart: notificationSettingCreatedAtStart === null ? null : notificationSettingCreatedAtStart.toISOString(),
                        createdAtEnd: notificationSettingCreatedAtEnd === null ? null : notificationSettingCreatedAtEnd.toISOString(),
                        updatedAtStart: notificationSettingUpdatedAtStart === null ? null : notificationSettingUpdatedAtStart.toISOString(),
                        updatedAtEnd: notificationSettingUpdatedAtEnd === null ? null : notificationSettingUpdatedAtEnd.toISOString(),
                        programId: programId.length === 0 ? null : (programId as unknown) as number,
                        logLevelId: logLevelId.length === 0 ? null : (logLevelId as unknown) as number,
                        userUsername: userUsername.length === 0 ? null : userUsername,
                        userEmail: userEmail.length === 0 ? null : userEmail,
                        userName: userName.length === 0 ? null : userName,
                        programName: programName.length === 0 ? null : programName,
                        logLevelName: logLevelName.length === 0 ? null : logLevelName,
                        logLevelValue: logLevelValue.length === 0 ? null : (logLevelValue as unknown) as number,
                        isNotify: notificationSettingIsNotify.length === 0 ? null : notificationSettingIsNotify === 'aktív' ? true : false,
                    },
                    orderBy: 'programName_asc',
                    pagination: {
                        currentPage: page + 1,
                        dataPerPage: rowsPerPage,
                    },

                    // 'id_asc'
                    // 'id_desc'
                    // 'createdAt_asc'
                    // 'createdAt_desc'
                    // 'updatedAt_asc'
                    // 'updatedAt_desc'
                    // 'programId_asc'
                    // 'programId_desc'
                    // 'logLevelId_asc'
                    // 'logLevelId_desc'
                    // 'userUsername_asc'
                    // 'userUsername_desc'
                    // 'userEmail_asc'
                    // 'userEmail_desc'
                    // 'userName_asc'
                    // 'userName_desc'
                    // 'programName_asc'
                    // 'programName_desc'
                    // 'logLevelName_asc'
                    // 'logLevelName_desc'
                    // 'logLevelValue_asc'
                    // 'logLevelValue_desc'
                    // 'isNotify_asc'
                    // 'isNotify_desc'
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async addNotificationSetting(programName: string, logLevelName: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.notificationSetting.addNotificationSetting,
                {
                    programName: programName,
                    logLevelName: logLevelName,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async updateNotificationSetting(
        notificationSettingId: number,
        programName: string,
        logLevelName: string,
        isNotify: boolean,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .put(
                getAppApiUrlBase() + getAppApiRoutes.notificationSetting.updateNotificationSetting.replace('{id}', (notificationSettingId as unknown) as string),
                {
                    programName: programName,
                    logLevelName: logLevelName,
                    isNotify: isNotify,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async removeNotificationSetting(notificationSettingId: number): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .delete(
                getAppApiUrlBase() + getAppApiRoutes.notificationSetting.removeNotificationSetting.replace('{id}', (notificationSettingId as unknown) as string),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }
}

// -----------------------------------------------------------------Exports---
export default new NotificationSettingService();
