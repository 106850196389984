// -----------------------------------------------------------------Imports---
import moment, { Moment } from 'moment';

import {
    ChangeEvent,
    Dispatch,
    useEffect,
    useReducer,
} from 'react';

import { toast } from 'react-toastify';

import { useDebounce } from 'use-debounce';

import {
    Add,
    Close,
    Delete,
    ExpandMore,
    Refresh,
    Settings,
} from '@mui/icons-material';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Backdrop,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    DialogStyles,
    FabStyles,
    FilterStyles,
    PaperStyles,
    TableSx,
    TextFieldStyles,
} from './ApiKeysPage.style';

import {
    numberInStringIsValid,
    momentIsValid,
    nameOf,
    setReducer,
} from '../../CodeKit';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    ReducerAction,
} from '../../Global';

import DateTimePickerInput from '../../components/dateTimePickerInput/DateTimePickerInput';
import DialogTransition from '../../components/dialogTransition/DialogTransition';

import ApiKeyEntity from '../../entities/ApiKeyEntity';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import ApiKeyService from '../../services/apiKey/ApiKeyService';

// ----------------------------------------------------------------Privates---
type PageModel = {
    apiKeys: ApiKeyEntity[];
    apiKeyNames: string[];
    apiKeyStates: string[];
    backdropOpen: boolean;
    updateTable: boolean;
    apiKeyIdFilter: string;
    apiKeyCreatedAtStartFilter: Moment | null;
    apiKeyCreatedAtEndFilter: Moment | null;
    apiKeyUpdatedAtStartFilter: Moment | null;
    apiKeyUpdatedAtEndFilter: Moment | null;
    userIdFilter: string;
    userUsernameFilter: string;
    userEmailFilter: string;
    userNameFilter: string;
    apiKeyFilter: string;
    apiKeyNameFilter: string;
    apiKeyIsEnabledFilter: string;
    openAddDialog: boolean;
    apiKeyNameAddDialog: string;
    apiKeyPasswordAddDialog: string;
    openUpdateDialog: boolean;
    apiKeyIdUpdateDialog: number;
    apiKeyNameUpdateDialog: string;
    apiKeyIsEnabledUpdateDialog: boolean;
    apiKeyPasswordUpdateDialog: string;
    openUpdateRegenerateDialog: boolean;
    apiKeyIdUpdateRegenerateDialog: number;
    apiKeyPasswordUpdateRegenerateDialog: string;
    openRemoveDialog: boolean;
    apiKeyIdRemoveDialog: number;
    apiKeyNameRemoveDialog: string;
    apiKeyPasswordRemoveDialog: string;
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

const initialState: PageModel = {
    apiKeys: [],
    apiKeyNames: [],
    apiKeyStates: ['aktív', 'inaktív'],
    backdropOpen: false,
    updateTable: false,
    apiKeyIdFilter: '',
    apiKeyCreatedAtStartFilter: null,
    apiKeyCreatedAtEndFilter: null,
    apiKeyUpdatedAtStartFilter: null,
    apiKeyUpdatedAtEndFilter: null,
    userIdFilter: '',
    userUsernameFilter: '',
    userEmailFilter: '',
    userNameFilter: '',
    apiKeyFilter: '',
    apiKeyNameFilter: '',
    apiKeyIsEnabledFilter: '',
    openAddDialog: false,
    apiKeyNameAddDialog: '',
    apiKeyPasswordAddDialog: '',
    openUpdateDialog: false,
    apiKeyIdUpdateDialog: -1,
    apiKeyNameUpdateDialog: '',
    apiKeyIsEnabledUpdateDialog: false,
    apiKeyPasswordUpdateDialog: '',
    openUpdateRegenerateDialog: false,
    apiKeyIdUpdateRegenerateDialog: -1,
    apiKeyPasswordUpdateRegenerateDialog: '',
    openRemoveDialog: false,
    apiKeyIdRemoveDialog: -1,
    apiKeyNameRemoveDialog: '',
    apiKeyPasswordRemoveDialog: '',
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

// -----------------------------------------------------------------Exports---
export default function ApiKeysPage(): JSX.Element {
    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    const [debouncedState] = useDebounce<PageModel>(state, 1000);

    useEffect(function (): void {
        async function GetApiKeys(): Promise<void> {
            const responseApiKeys: ResponseModel = await ApiKeyService.getApiKeys('', null, null, null, null, '', '', '', '', '', '', '', 0, 2147483647);

            if (responseApiKeys.status === 200) {
                let responseApiKeyNames: string[] = [];

                (responseApiKeys.data as ApiKeyEntity[]).map(function (logLevel: ApiKeyEntity): void {
                    responseApiKeyNames.push(logLevel.name!);
                    return undefined;
                });

                dispatch({
                    field: nameOf<PageModel>('apiKeyNames'),
                    payload: responseApiKeyNames,
                });
            }
            else if (responseApiKeys.status === 401) {
                toast.error('Sikertelen autentikáció.');
            }
            else {
                toast.error(responseApiKeys.error!.message!);

                if (responseApiKeys.error!.fields !== null) {
                    (responseApiKeys.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        GetApiKeys();
    }, []);

    useEffect(
        function (): void {
            async function GetApiKeys(): Promise<void> {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: true,
                });

                const response: ResponseModel = await ApiKeyService.getApiKeys(
                    debouncedState.apiKeyIdFilter,
                    debouncedState.apiKeyCreatedAtStartFilter,
                    debouncedState.apiKeyCreatedAtEndFilter,
                    debouncedState.apiKeyUpdatedAtStartFilter,
                    debouncedState.apiKeyUpdatedAtEndFilter,
                    debouncedState.userIdFilter,
                    debouncedState.userUsernameFilter,
                    debouncedState.userEmailFilter,
                    debouncedState.userNameFilter,
                    debouncedState.apiKeyFilter,
                    debouncedState.apiKeyNameFilter,
                    debouncedState.apiKeyIsEnabledFilter,
                    state.page,
                    state.rowsPerPage,
                );

                if (response.status === 200) {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });
                    dispatch({
                        field: nameOf<PageModel>('apiKeys'),
                        payload: response.data as ApiKeyEntity[],
                    });
                    dispatch({
                        field: nameOf<PageModel>('rowCount'),
                        payload: response.pagination!.totalDataCount!,
                    });
                }
                else if (response.status === 401) {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });
                    toast.error('Sikertelen autentikáció.');
                }
                else {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });

                    toast.error(response.error!.message!);

                    if (response.error!.fields !== null) {
                        (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                            toast.error(field.field! + ': ' + field.message!);
                            return undefined;
                        });
                    }
                }
            }

            GetApiKeys();
        },
        [
            debouncedState.apiKeyIdFilter,
            debouncedState.apiKeyCreatedAtStartFilter,
            debouncedState.apiKeyCreatedAtEndFilter,
            debouncedState.apiKeyUpdatedAtStartFilter,
            debouncedState.apiKeyUpdatedAtEndFilter,
            debouncedState.userIdFilter,
            debouncedState.userUsernameFilter,
            debouncedState.userEmailFilter,
            debouncedState.userNameFilter,
            debouncedState.apiKeyFilter,
            debouncedState.apiKeyNameFilter,
            debouncedState.apiKeyIsEnabledFilter,
            state.updateTable,
            state.page,
            state.rowsPerPage,
        ]
    );

    // --------------------------------------------------------------Events---
    function isOptionEqualToValueFromAutocomplete(option: string, value: string): boolean {
        return option === value;
    }

    function onChangeApiKeyIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('apiKeyIdFilter'),
            payload: event.target.value,
        });
    }

    function onInputChangeApiKeyNameFilter(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyNameFilter'),
            payload: newValue,
        });
    }

    function onChangeApiKeyFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyFilter'),
            payload: event.target.value,
        });
    }

    function onInputChangeApiKeyIsEnabledFilter(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIsEnabledFilter'),
            payload: newValue,
        });
    }

    function onChangeApiKeyCreatedAtStartFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('apiKeyCreatedAtStartFilter'),
            payload: value,
        });
    }

    function onChangeApiKeyCreatedAtEndFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('apiKeyCreatedAtEndFilter'),
            payload: value,
        });
    }

    function onChangeApiKeyUpdatedAtStartFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('apiKeyUpdatedAtStartFilter'),
            payload: value,
        });
    }

    function onChangeApiKeyUpdatedAtEndFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('apiKeyUpdatedAtEndFilter'),
            payload: value,
        });
    }

    function onChangeUserIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('userIdFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserUsernameFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userUsernameFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserNameFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userNameFilter'),
            payload: event.target.value,
        });
    }

    function onChangeApiKeyNameAddDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyNameAddDialog'),
            payload: event.target.value,
        });
    }

    function onChangeApiKeyPasswordAddDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordAddDialog'),
            payload: event.target.value,
        });
    }

    function onChangeApiKeyNameUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyNameUpdateDialog'),
            payload: event.target.value,
        });
    }

    function onChangeIsEnabledUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIsEnabledUpdateDialog'),
            payload: event.target.checked,
        });
    }

    function onChangeApiKeyPasswordUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordUpdateDialog'),
            payload: event.target.value,
        });
    }

    function onChangeApiKeyPasswordUpdateRegenerateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordUpdateRegenerateDialog'),
            payload: event.target.value,
        });
    }

    function onChangeApiKeyPasswordRemoveDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordRemoveDialog'),
            payload: event.target.value,
        });
    }

    function onClickAddButton(): void {
        dispatch({
            field: nameOf<PageModel>('openAddDialog'),
            payload: true,
        });
    }

    function onCloseAddDialog(): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyNameAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openAddDialog'),
            payload: false,
        });
    }

    async function onClickAddButtonFromDialog(): Promise<void> {
        if (state.apiKeyPasswordAddDialog.length === 0) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ApiKeyService.addApiKey(state.apiKeyPasswordAddDialog, state.apiKeyNameAddDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success('Sikeres hozzáadás.');

            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseAddDialog();
    }

    function onClickUpdateButton(apiKeyId: number, apiKeyName: string, apiKeyIsEnabled: boolean): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIdUpdateDialog'),
            payload: apiKeyId,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyNameUpdateDialog'),
            payload: apiKeyName,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyIsEnabledUpdateDialog'),
            payload: apiKeyIsEnabled,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateDialog'),
            payload: true,
        });
    }

    function onCloseUpdateDialog(): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIdUpdateDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyNameUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyIsEnabledUpdateDialog'),
            payload: false,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateDialog'),
            payload: false,
        });
    }

    async function onClickUpdateButtonFromDialog(): Promise<void> {
        if (state.apiKeyPasswordUpdateDialog.length === 0) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ApiKeyService.updateApiKey(state.apiKeyIdUpdateDialog, state.apiKeyPasswordUpdateDialog, state.apiKeyNameUpdateDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success('Sikeres frissítés.');

            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseUpdateDialog();
    }

    function onClickUpdateRegenerateButton(apiKeyId: number): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIdUpdateRegenerateDialog'),
            payload: apiKeyId,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordUpdateRegenerateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateRegenerateDialog'),
            payload: true,
        });
    }

    function onCloseUpdateRegenerateDialog(): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIdUpdateRegenerateDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordUpdateRegenerateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateRegenerateDialog'),
            payload: false,
        });
    }

    async function onClickUpdateRegenerateButtonFromDialog(): Promise<void> {
        if (state.apiKeyPasswordUpdateRegenerateDialog.length === 0) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ApiKeyService.updateApiKeyRegenerate(state.apiKeyIdUpdateRegenerateDialog, state.apiKeyPasswordUpdateRegenerateDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success('Sikeres frissítés.');

            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseUpdateRegenerateDialog();
    }

    function onClickRemoveButton(apiKeyId: number, apiKeyName: string): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIdRemoveDialog'),
            payload: apiKeyId,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyNameRemoveDialog'),
            payload: apiKeyName,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordRemoveDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openRemoveDialog'),
            payload: true,
        });
    }

    function onCloseRemoveDialog(): void {
        dispatch({
            field: nameOf<PageModel>('apiKeyIdRemoveDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyNameRemoveDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('apiKeyPasswordRemoveDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openRemoveDialog'),
            payload: false,
        });
    }

    async function onClickRemoveButtonFromDialog(): Promise<void> {
        if (state.apiKeyPasswordRemoveDialog.length === 0) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ApiKeyService.removeApiKey(state.apiKeyIdRemoveDialog, state.apiKeyPasswordRemoveDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success((response.data! as unknown) as string);
            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error(response.error!.message!);
        }

        onCloseRemoveDialog();
    }

    function onPageChange(event: unknown, newPage: number): void {
        dispatch({
            field: nameOf<PageModel>('page'),
            payload: newPage,
        });
    }

    function onRowsPerPageChange(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('rowsPerPage'),
            payload: (event.target.value as unknown) as number,
        });
        dispatch({
            field: nameOf<PageModel>('page'),
            payload: 0,
        });
    }

    function onClickBackdropToggle(): void {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: !state.backdropOpen,
        });
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Paper style={PaperStyles.filter}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                Szűrés
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="API kulcs létrehozás időbélyeg kezdet" onChange={onChangeApiKeyCreatedAtStartFilter} renderInput={DateTimePickerInput} value={state.apiKeyCreatedAtStartFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="API kulcs létrehozás időbélyeg vég" onChange={onChangeApiKeyCreatedAtEndFilter} renderInput={DateTimePickerInput} value={state.apiKeyCreatedAtEndFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="API kulcs módosítás időbélyeg kezdet" onChange={onChangeApiKeyUpdatedAtStartFilter} renderInput={DateTimePickerInput} value={state.apiKeyUpdatedAtStartFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="API kulcs módosítás időbélyeg vég" onChange={onChangeApiKeyUpdatedAtEndFilter} renderInput={DateTimePickerInput} value={state.apiKeyUpdatedAtEndFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="API kulcs azonosító" onChange={onChangeApiKeyIdFilter} style={FilterStyles.filter} type="number" value={state.apiKeyIdFilter} />
                                    </Grid>
                                )}
                                <Grid item md={3} sm={6} xs={12}>
                                    <Autocomplete autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeApiKeyNameFilter} options={state.apiKeyNames} renderInput={(params) => <TextField {...params} autoFocus label="API kulcs megnevezés" style={FilterStyles.filter} />} value={state.apiKeyNameFilter} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <TextField label="API kulcs" onChange={onChangeApiKeyFilter} style={FilterStyles.filter} type="text" value={state.apiKeyFilter} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <Autocomplete autoComplete disablePortal filterSelectedOptions isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeApiKeyIsEnabledFilter} options={state.apiKeyStates} renderInput={(params) => <TextField {...params} label="API kulcs állapot" style={FilterStyles.filter} />} value={state.apiKeyIsEnabledFilter} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos azonosító" onChange={onChangeUserIdFilter} style={FilterStyles.filter} type="number" value={state.userIdFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos felhasználónév" onChange={onChangeUserUsernameFilter} style={FilterStyles.filter} type="text" value={state.userUsernameFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos név" onChange={onChangeUserNameFilter} style={FilterStyles.filter} type="text" value={state.userNameFilter} />
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </LocalizationProvider>

            <Paper style={PaperStyles.main}>
                <TablePagination component="div" count={state.rowCount} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead sx={TableSx().row.title}>
                            <TableRow>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            API kulcs azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        API kulcs megnevezés
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        API kulcs
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        API kulcs státusz
                                    </TableSortLabel>
                                </TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos felhasználónév
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos név
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        API kulcs létrehozási időbélyeg
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        API kulcs módosítási időbélyeg
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.apiKeys.map(function (apiKey: ApiKeyEntity, index: number): JSX.Element {
                                return (
                                    <TableRow key={apiKey.id!} sx={index % 2 === 1 ? TableSx().row.odd : TableSx().row.even}>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{apiKey.id!}</TableCell>
                                        )}
                                        <TableCell>{apiKey.name!}</TableCell>
                                        <TableCell>{apiKey.apiKey!}</TableCell>
                                        <TableCell>{apiKey.isEnabled! ? <Chip color="success" label="aktív" /> : <Chip color="error" label="inaktív" />}</TableCell>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{apiKey.user!.id!}</TableCell>
                                        )}
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell>{apiKey.user!.username!}</TableCell>
                                        )}
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell>{apiKey.user!.name!}</TableCell>
                                        )}
                                        <TableCell align="center">{moment(apiKey.createdAt!).format(dateTimeFormat)}</TableCell>
                                        <TableCell align="center">{moment(apiKey.updatedAt!).format(dateTimeFormat)}</TableCell>
                                        <TableCell align="center"><Fab aria-label="update" color="warning" onClick={() => onClickUpdateButton(apiKey.id!, apiKey.name!, apiKey.isEnabled!)} size="small"><Settings /></Fab></TableCell>
                                        <TableCell align="center"><Fab aria-label="updateRegenerate" color="info" onClick={() => onClickUpdateRegenerateButton(apiKey.id!)} size="small"><Refresh /></Fab></TableCell>
                                        <TableCell align="center"><Fab aria-label="remove" color="error" onClick={() => onClickRemoveButton(apiKey.id!, apiKey.name!)} size="small"><Delete /></Fab></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter sx={TableSx().row.title}>
                            <TableRow sx={TableSx().row.lastElement}>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">API kulcs azonosító</TableCell>
                                )}
                                <TableCell>API kulcs megnevezés</TableCell>
                                <TableCell>API kulcs</TableCell>
                                <TableCell>API kulcs státusz</TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Tulajdonos azonosító</TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell>Tulajdonos felhasználónév</TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell>Tulajdonos név</TableCell>
                                )}
                                <TableCell align="center">API kulcs létrehozási időbélyeg</TableCell>
                                <TableCell align="center">API kulcs módosítási időbélyeg</TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <TablePagination component="div" count={state.rowCount} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} style={TableSx().paginationBottom} />
            </Paper>

            <Fab aria-label="add" color="success" onClick={onClickAddButton} style={FabStyles.add}><Add /></Fab>

            <Dialog onClose={onCloseAddDialog} open={state.openAddDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Hozzáadás</DialogTitle>
                <DialogContent>
                    <TextField autoFocus fullWidth label="Megnevezés" onChange={onChangeApiKeyNameAddDialog} style={TextFieldStyles.dialog} type="text" value={state.apiKeyNameAddDialog} />
                    <TextField fullWidth label="Jelszó" onChange={onChangeApiKeyPasswordAddDialog} style={TextFieldStyles.dialog} type="password" value={state.apiKeyPasswordAddDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseAddDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="success" onClick={onClickAddButtonFromDialog} startIcon={<Add />} variant="contained">Hozzáadás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseUpdateDialog} open={state.openUpdateDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Frissítés</DialogTitle>
                <DialogContent>
                    <TextField autoFocus fullWidth label="Megnevezés" onChange={onChangeApiKeyNameUpdateDialog} style={TextFieldStyles.dialog} type="text" value={state.apiKeyNameUpdateDialog} />
                    <Switch checked={state.apiKeyIsEnabledUpdateDialog} onChange={onChangeIsEnabledUpdateDialog} />
                    <TextField fullWidth label="Jelszó" onChange={onChangeApiKeyPasswordUpdateDialog} style={TextFieldStyles.dialog} type="password" value={state.apiKeyPasswordUpdateDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseUpdateDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="warning" onClick={onClickUpdateButtonFromDialog} startIcon={<Settings />} variant="contained">Módosítás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseUpdateRegenerateDialog} open={state.openUpdateRegenerateDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Frissítés (újra generálás)</DialogTitle>
                <DialogContent>
                    <TextField autoFocus fullWidth label="Jelszó" onChange={onChangeApiKeyPasswordUpdateRegenerateDialog} style={TextFieldStyles.dialog} type="password" value={state.apiKeyPasswordUpdateRegenerateDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseUpdateRegenerateDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="warning" onClick={onClickUpdateRegenerateButtonFromDialog} startIcon={<Settings />} variant="contained">Módosítás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseRemoveDialog} open={state.openRemoveDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Törlés</DialogTitle>
                <DialogContent>
                    <DialogContentText style={DialogStyles.remove}>Biztos törlöd a kijelölt API kulcsot: <br /><br />"{state.apiKeyNameRemoveDialog}"?</DialogContentText>
                    <TextField autoFocus fullWidth label="Jelszó" onChange={onChangeApiKeyPasswordRemoveDialog} style={TextFieldStyles.dialog} type="password" value={state.apiKeyPasswordRemoveDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseRemoveDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="error" onClick={onClickRemoveButtonFromDialog} startIcon={<Delete />} variant="contained">Törlés</Button>
                </DialogActions>
            </Dialog>

            <Backdrop onClick={onClickBackdropToggle} open={state.backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </>
    );
}
