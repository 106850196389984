// -----------------------------------------------------------------Imports---
import {
    Dispatch,
    MouseEvent,
    useReducer,
} from 'react';

import {
    Location,
    NavigateFunction,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import MenuIcon from '@mui/icons-material/Menu';

import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';

import {
    BoxSx,
    ButtonSx,
    Images,
    ImageSx,
    MenuOrigins,
    MenuItemStyles,
    MenuSx,
    IconButtonSx,
} from './HeaderComponent.style';

import {
    nameOf,
    setReducer,
} from '../../CodeKit';

import {
    getAppRoutes,
    ReducerAction,
} from '../../Global';

import AuthenticationService from '../../services/authentication/AuthenticationService';

// ----------------------------------------------------------------Privates---
type PageModel = {
    mainMenuOpen: HTMLElement | null;
    userMenuOpen: HTMLElement | null;
}

const initialState: PageModel = {
    mainMenuOpen: null,
    userMenuOpen: null,
}

// -----------------------------------------------------------------Exports---
export default function HeaderComponent(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation();

    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    // -----------------------------------------------------------Functions---
    function isUrlMatches(url: string): boolean {
        return location.pathname.indexOf(url) !== -1;
    }

    // --------------------------------------------------------------Events---
    function onClickMainMenuOpen(e: MouseEvent<HTMLElement>): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: e.currentTarget,
        });
    }

    function onClickMainMenuClose(): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: null,
        });
    }

    function onClickUserMenuOpen(e: MouseEvent<HTMLElement>): void {
        dispatch({
            field: nameOf<PageModel>('userMenuOpen'),
            payload: e.currentTarget,
        });
    }

    function onClickUserMenuClose(): void {
        dispatch({
            field: nameOf<PageModel>('userMenuOpen'),
            payload: null,
        });
    }

    function onClickHome(): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.main);
    }

    function onClickPrograms(): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.programs);
    }

    function onClickLogs(): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.logs);
    }

    function onClickNotifications(): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.notifications);
    }

    function onClickAbout(): void {
        dispatch({
            field: nameOf<PageModel>('mainMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.about);
    }

    function onClickAccount(): void {
        dispatch({
            field: nameOf<PageModel>('userMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.account);
    }

    function onClickApiKeys(): void {
        dispatch({
            field: nameOf<PageModel>('userMenuOpen'),
            payload: null,
        });
        navigate(getAppRoutes.apiKeys);
    }

    function onClickLogout(): void {
        AuthenticationService.logout();
        toast.success('Sikeres kijelentkezés.');
        navigate(getAppRoutes.login);
    }

    // --------------------------------------------------------------Return---
    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>

                    {/* Medium logo */}
                    <Link component="button" onClick={onClickHome}>
                        <Box alt="productLogoMedium" component="img" src={Images.productLogoWithoutIcon} sx={ImageSx.logoMedium} />
                    </Link>

                    {/* ExtraSmall menü kezelőfelület */}
                    <Box sx={BoxSx.extraSmall}>
                        <IconButton aria-controls="menu-appbar" aria-haspopup="true" aria-label="account" onClick={onClickMainMenuOpen} size="large">
                            <MenuIcon />
                        </IconButton>

                        <Menu anchorEl={state.mainMenuOpen} anchorOrigin={MenuOrigins.extraSmallAnchor} id="menu-appbar" keepMounted onClose={onClickMainMenuClose} open={Boolean(state.mainMenuOpen)} sx={MenuSx.extraSmall} transformOrigin={MenuOrigins.extraSmallTransform}>
                            <MenuItem onClick={onClickPrograms} style={isUrlMatches('programs') ? MenuItemStyles.currentMenu : MenuItemStyles.menu}>
                                <Typography textAlign="center">
                                    Programok
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={onClickLogs} style={isUrlMatches('logs') ? MenuItemStyles.currentMenu : MenuItemStyles.menu}>
                                <Typography textAlign="center">
                                    Naplók
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={onClickNotifications} style={isUrlMatches('notifications') ? MenuItemStyles.currentMenu : MenuItemStyles.menu}>
                                <Typography textAlign="center">
                                    Értesítések
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={onClickAbout} style={isUrlMatches('about') ? MenuItemStyles.currentMenu : MenuItemStyles.menu}>
                                <Typography textAlign="center">
                                    Névjegy
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>

                    {/* ExtraSmall logo */}
                    <Link component="button" onClick={onClickHome}>
                        <Box alt="productLogoExtraSmall" component="img" src={Images.productLogoWithoutIcon} sx={ImageSx.logoExtraSmall} />
                    </Link>

                    {/* Medium menü kezelőfelület */}
                    <Box sx={BoxSx.medium}>
                        <Button onClick={onClickPrograms} sx={isUrlMatches('programs') ? ButtonSx.currentMenu : ButtonSx.menu}>
                            Programok
                        </Button>
                        <Button onClick={onClickLogs} sx={isUrlMatches('logs') ? ButtonSx.currentMenu : ButtonSx.menu}>
                            Logok
                        </Button>
                        <Button onClick={onClickNotifications} sx={isUrlMatches('notifications') ? ButtonSx.currentMenu : ButtonSx.menu}>
                            Értesítések
                        </Button>
                        <Button onClick={onClickAbout} sx={isUrlMatches('about') ? ButtonSx.currentMenu : ButtonSx.menu}>
                            Névjegy
                        </Button>
                    </Box>

                    {/* Felhasználói kezelőfelület */}
                    <Box sx={BoxSx.user}>
                        <Tooltip title={localStorage.getItem('name')!}>
                            <IconButton onClick={onClickUserMenuOpen} sx={IconButtonSx.user}>
                                <Avatar alt={localStorage.getItem('name')!} src="/static/images/avatar/2.jpg" />
                            </IconButton>
                        </Tooltip>

                        <Menu anchorEl={state.userMenuOpen} anchorOrigin={MenuOrigins.userAnchor} id="menu-appbar" keepMounted onClose={onClickUserMenuClose} open={Boolean(state.userMenuOpen)} sx={MenuSx.user} transformOrigin={MenuOrigins.userTransform}>
                            <MenuItem onClick={onClickAccount} style={isUrlMatches('account') ? MenuItemStyles.currentMenu : MenuItemStyles.menu}>
                                <Typography textAlign="center">
                                    Fiók
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={onClickApiKeys} style={isUrlMatches('apiKeys') ? MenuItemStyles.currentMenu : MenuItemStyles.menu}>
                                <Typography textAlign="center">
                                    API kulcsok
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={onClickLogout}>
                                <Typography textAlign="center">
                                    Kijelentkezés
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
