// -----------------------------------------------------------------Imports---
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Divider,
    Grid,
    Link,
    Typography,
} from '@mui/material';

import {
    AccordionStyle,
    CardStyles,
    CardSx,
    DividerStyles,
    GridStyles,
} from './AboutPage.style';

// -----------------------------------------------------------------Exports---
export default function AboutPage(): JSX.Element {
    // --------------------------------------------------------------Return---
    return (
        <Card style={CardStyles.main} sx={CardSx().main}>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid item style={GridStyles.right} xs={5}>Név</Grid>
                    <Grid item xs={7}>Logger</Grid>
                </Grid>

                <Divider style={DividerStyles.main} />

                <Grid container spacing={1}>
                    <Grid item style={GridStyles.right} xs={5}>Verzió</Grid>
                    <Grid item xs={7}>v0.5.0.0</Grid>
                </Grid>

                <Divider style={DividerStyles.main} />

                <Grid container spacing={1}>
                    <Grid item style={GridStyles.right} xs={5}>Fejlesztő</Grid>
                    <Grid item xs={7}>SzoftWeb</Grid>
                </Grid>

                <Divider style={DividerStyles.main} />

                <Grid container spacing={1}>
                    <Grid item style={GridStyles.right} xs={5}>Kapcsolat</Grid>
                    <Grid item xs={7}><Link href="mailto:info@szoftweb.hu">info@szoftweb.hu</Link></Grid>
                </Grid>

                <Divider style={DividerStyles.main} />

                <Grid container spacing={1}>
                    <Grid item style={GridStyles.right} xs={12}>
                        <Accordion style={AccordionStyle.main}>
                            <AccordionSummary>
                                <Typography>
                                    Verzió történet
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.5.0.0</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>Debounce módszer hozzáadása a szűrőkhöz. Így minden szűrés egy másodperces késleltetéssel kéri le a szűrt adatokat a szervertől.</Grid>
                                </Grid>

                                <Divider style={DividerStyles.main} />

                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.4.0.0</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>API kulcsok bevezetése, extra hitelesítés kérése felhasználói adatok módosításakor.</Grid>
                                </Grid>

                                <Divider style={DividerStyles.main} />

                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.3.0.0</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>Főoldali számszerű statisztikák hozzáadása.</Grid>
                                </Grid>

                                <Divider style={DividerStyles.main} />

                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.2.0.0</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>Fiók lap hozzáadása.</Grid>
                                </Grid>

                                <Divider style={DividerStyles.main} />

                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.1.0.1</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>Natív rendszerértesítések hozzáadása az újonnan érkezett logokról.</Grid>
                                </Grid>

                                <Divider style={DividerStyles.main} />

                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.1.0.0</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>Alap kiadás frissítése, hogy gördülékenyebb legyen, hibák nélkül. A listázót tartalmazó lapokon a szűrések is hozzáadásra kerültek.</Grid>
                                </Grid>

                                <Divider style={DividerStyles.main} />

                                <Grid container spacing={1}>
                                    <Grid item style={GridStyles.left} xs={2}>v0.0.0.1</Grid>
                                    <Grid item style={GridStyles.justify} xs={10}>Alap verzió kiadása, mely tartalmazza a bejelentkező, regisztráció, programok, értesítések és logok lapokat.</Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
