// -----------------------------------------------------------------Imports---
import moment, { Moment } from 'moment';

import { useEffect } from 'react';

import addNotification, { Notifications } from 'react-push-notification';
import { Options } from 'react-push-notification/dist/notifications/Storage';

import {
    Location,
    NavigateFunction,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    toast,
    ToastContainer,
} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import {
    CssBaseline,
    ThemeProvider,
} from '@mui/material';

import { GlobalTheme } from './App.style';

import { getAppRoutes } from './Global';

import HeaderComponent from './components/header/HeaderComponent';

import LogEntity from './entities/LogEntity';

import FieldValidationErrorModel from './models/FieldValidationErrorModel';
import ResponseModel from './models/ResponseModel';

import AboutPage from './pages/about/AboutPage';
import AccountPage from './pages/account/AccountPage';
import ApiKeysPage from './pages/apiKeys/ApiKeysPage';
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/login/LoginPage';
import LogsPage from './pages/logs/LogsPage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import NotificationsPage from './pages/notifications/NotificationsPage';
import ProgramsPage from './pages/programs/ProgramsPage';
import RegistrationPage from './pages/registration/RegistrationPage';

import AuthenticationService from './services/authentication/AuthenticationService';
import LogService from './services/log/LogService';

// -----------------------------------------------------------------Exports---
export default function App(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();
    const location: Location = useLocation();

    let loggedInState: boolean = AuthenticationService.loggedInState();
    let logs: LogEntity[] = [];
    let logsReferenceDate: Moment | null = moment(new Date());

    useEffect(function (): void {
        const exceptPaths: string[] = [getAppRoutes.login, getAppRoutes.registration];

        if ((loggedInState) && (exceptPaths.indexOf(location.pathname) !== -1)) {
            navigate(getAppRoutes.main);
        }
        else if ((!loggedInState) && (exceptPaths.indexOf(location.pathname) === -1)) {
            navigate(getAppRoutes.login);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedInState]);

    useEffect(function (): void {
        async function GetLogs(): Promise<void> {
            const response: ResponseModel = await LogService.getLogs('', logsReferenceDate, null, null, null, '', '', '', '', '', '', '', '', '', '', '', '', 0, 2147483647);

            if (response.status === 200) {
                // eslint-disable-next-line
                logs = response.data as LogEntity[];
            }
            else if (response.status === 401) {
                toast.error('Sikertelen autentikáció.');
            }
            else {
                toast.error(response.error!.message!);

                if (response.error!.fields !== null) {
                    (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        setInterval(function (): void {
            // eslint-disable-next-line
            loggedInState = AuthenticationService.loggedInState();

            if (loggedInState) {
                GetLogs();

                if (logs.length !== 0) {
                    let options: Options = { title: '' }

                    if (logs.length === 1) {
                        logs.map(function (log: LogEntity): void {
                            options = {
                                title: 'Új napló bejegyzés érkezett',
                                message: log.program!.name! + ' (' + log.logLevel!.name! + ') [' + log.version! + ']: ' + log.message!,
                                onClick: onClickNotification,
                                native: true,
                                duration: 5000,
                            }
                            return undefined;
                        });
                    }
                    else {
                        options = {
                            title: 'Új napló bejegyzés érkezett',
                            message: ((logs.length as unknown) as string) + ' új napló bejegyzés érkezett',
                            onClick: onClickNotification,
                            native: true,
                            duration: 5000,
                        }
                    }

                    addNotification(options);
                }
            }

            // eslint-disable-next-line
            logsReferenceDate = moment(new Date());
            logs = [];
        }, 60000);
    }, []);

    window.onerror = function (event: string | Event, source?: string | undefined, lineno?: number | undefined, colno?: number | undefined, error?: Error | undefined) {
        console.log(event);
        // TODO: Loggerbe írás
    }

    // --------------------------------------------------------------Events---
    function onClickNotification(event: Event | Notification): void {
        if (location.pathname !== getAppRoutes.logs) {
            navigate(getAppRoutes.logs);
        }
    }

    // --------------------------------------------------------------Return---
    return (
        <ThemeProvider theme={GlobalTheme}>
            <CssBaseline enableColorScheme />
            <ToastContainer theme={'dark'} position={'bottom-right'} />
            <Notifications />
            {loggedInState ? <HeaderComponent /> : <></>}
            <Routes>
                <Route element={<AboutPage />} path={getAppRoutes.about} />
                <Route element={<AccountPage />} path={getAppRoutes.account} />
                <Route element={<ApiKeysPage />} path={getAppRoutes.apiKeys} />
                <Route element={<HomePage />} path={getAppRoutes.main} />
                <Route element={<LoginPage />} path={getAppRoutes.login} />
                <Route element={<LogsPage />} path={getAppRoutes.logs} />
                <Route element={<NotFoundPage />} path={getAppRoutes.notFound} />
                <Route element={<NotificationsPage />} path={getAppRoutes.notifications} />
                <Route element={<ProgramsPage />} path={getAppRoutes.programs} />
                <Route element={<RegistrationPage />} path={getAppRoutes.registration} />
            </Routes>
        </ThemeProvider>
    );
}
