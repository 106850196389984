// -----------------------------------------------------------------Imports---
import moment, { Moment } from 'moment';

import {
    ChangeEvent,
    Dispatch,
    useEffect,
    useReducer,
} from 'react';

import { toast } from 'react-toastify';

import { useDebounce } from 'use-debounce';

import {
    Add,
    Close,
    Delete,
    ExpandMore,
    Settings,
} from '@mui/icons-material';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    DialogStyles,
    FabStyles,
    FilterStyles,
    PaperStyles,
    TableSx,
    TextFieldStyles,
} from './ProgramsPage.style';

import {
    numberInStringIsValid,
    momentIsValid,
    nameOf,
    setReducer,
} from '../../CodeKit';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    ReducerAction,
} from '../../Global';

import DateTimePickerInput from '../../components/dateTimePickerInput/DateTimePickerInput';
import DialogTransition from '../../components/dialogTransition/DialogTransition';

import ProgramEntity from '../../entities/ProgramEntity';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import ProgramService from '../../services/program/ProgramService';

// ----------------------------------------------------------------Privates---
type PageModel = {
    programs: ProgramEntity[];
    programNames: string[];
    backdropOpen: boolean;
    updateTable: boolean;
    programIdFilter: string;
    programNameFilter: string;
    programCreatedAtStartFilter: Moment | null;
    programCreatedAtEndFilter: Moment | null;
    programUpdatedAtStartFilter: Moment | null;
    programUpdatedAtEndFilter: Moment | null;
    userIdFilter: string;
    userUsernameFilter: string;
    userNameFilter: string;
    openAddDialog: boolean;
    programNameAddDialog: string;
    openUpdateDialog: boolean;
    programIdUpdateDialog: number;
    programNameUpdateDialog: string;
    openRemoveDialog: boolean;
    programIdRemoveDialog: number;
    programNameRemoveDialog: string;
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

const initialState: PageModel = {
    programs: [],
    programNames: [],
    backdropOpen: false,
    updateTable: false,
    programIdFilter: '',
    programNameFilter: '',
    programCreatedAtStartFilter: null,
    programCreatedAtEndFilter: null,
    programUpdatedAtStartFilter: null,
    programUpdatedAtEndFilter: null,
    userIdFilter: '',
    userUsernameFilter: '',
    userNameFilter: '',
    openAddDialog: false,
    programNameAddDialog: '',
    openUpdateDialog: false,
    programIdUpdateDialog: -1,
    programNameUpdateDialog: '',
    openRemoveDialog: false,
    programIdRemoveDialog: -1,
    programNameRemoveDialog: '',
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

// -----------------------------------------------------------------Exports---
export default function ProgramsPage(): JSX.Element {
    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    const [debouncedState] = useDebounce<PageModel>(state, 1000);

    useEffect(function (): void {
        async function GetPrograms(): Promise<void> {
            const responseProrams: ResponseModel = await ProgramService.getPrograms('', '', null, null, null, null, '', '', '', '', 0, 2147483647);

            if (responseProrams.status === 200) {
                let responseProgramNames: string[] = [];

                (responseProrams.data as ProgramEntity[]).map(function (program: ProgramEntity): void {
                    responseProgramNames.push(program.name!);
                    return undefined;
                });

                dispatch({
                    field: nameOf<PageModel>('programNames'),
                    payload: responseProgramNames,
                });
            }
            else if (responseProrams.status === 401) {
                toast.error('Sikertelen autentikáció.');
            }
            else {
                toast.error(responseProrams.error!.message!);

                if (responseProrams.error!.fields !== null) {
                    (responseProrams.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        GetPrograms();
    }, []);

    useEffect(
        function (): void {
            async function GetPrograms(): Promise<void> {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: true,
                });

                const response: ResponseModel = await ProgramService.getPrograms(
                    debouncedState.programIdFilter,
                    debouncedState.programNameFilter,
                    debouncedState.programCreatedAtStartFilter,
                    debouncedState.programCreatedAtEndFilter,
                    debouncedState.programUpdatedAtStartFilter,
                    debouncedState.programUpdatedAtEndFilter,
                    debouncedState.userIdFilter,
                    debouncedState.userUsernameFilter,
                    debouncedState.userNameFilter,
                    '',
                    state.page,
                    state.rowsPerPage,
                );

                if (response.status === 200) {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });
                    dispatch({
                        field: nameOf<PageModel>('programs'),
                        payload: response.data as ProgramEntity[],
                    });
                    dispatch({
                        field: nameOf<PageModel>('rowCount'),
                        payload: response.pagination!.totalDataCount!,
                    });
                }
                else if (response.status === 401) {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });
                    toast.error('Sikertelen autentikáció.');
                }
                else {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });

                    toast.error(response.error!.message!);

                    if (response.error!.fields !== null) {
                        (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                            toast.error(field.field! + ': ' + field.message!);
                            return undefined;
                        });
                    }
                }
            }

            GetPrograms();
        },
        [
            debouncedState.programIdFilter,
            debouncedState.programNameFilter,
            debouncedState.programCreatedAtStartFilter,
            debouncedState.programCreatedAtEndFilter,
            debouncedState.programUpdatedAtStartFilter,
            debouncedState.programUpdatedAtEndFilter,
            debouncedState.userIdFilter,
            debouncedState.userUsernameFilter,
            debouncedState.userNameFilter,
            state.updateTable,
            state.page,
            state.rowsPerPage,
        ]
    );

    // --------------------------------------------------------------Events---
    function isOptionEqualToValueFromAutocomplete(option: string, value: string): boolean {
        return option === value;
    }

    function onChangeProgramIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('programIdFilter'),
            payload: event.target.value,
        });
    }

    function onInputChangeProgramNameFilter(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('programNameFilter'),
            payload: newValue,
        });
    }

    function onChangeProgramCreatedAtStartFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('programCreatedAtStartFilter'),
            payload: value,
        });
    }

    function onChangeProgramCreatedAtEndFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('programCreatedAtEndFilter'),
            payload: value,
        });
    }

    function onChangeProgramUpdatedAtStartFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('programUpdatedAtStartFilter'),
            payload: value,
        });
    }

    function onChangeProgramUpdatedAtEndFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('programUpdatedAtEndFilter'),
            payload: value,
        });
    }

    function onChangeUserIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('userIdFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserUsernameFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userUsernameFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserNameFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userNameFilter'),
            payload: event.target.value,
        });
    }

    function onChangeProgramNameAddDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('programNameAddDialog'),
            payload: event.target.value,
        });
    }

    function onChangeProgramNameUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('programNameUpdateDialog'),
            payload: event.target.value,
        });
    }

    function onClickAddButton(): void {
        dispatch({
            field: nameOf<PageModel>('openAddDialog'),
            payload: true,
        });
    }

    function onCloseAddDialog(): void {
        dispatch({
            field: nameOf<PageModel>('programNameAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openAddDialog'),
            payload: false,
        });
    }

    async function onClickAddButtonFromDialog(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ProgramService.addProgram(state.programNameAddDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success('Sikeres hozzáadás.');

            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseAddDialog();
    }

    function onClickUpdateButton(programId: number, programName: string): void {
        dispatch({
            field: nameOf<PageModel>('programIdUpdateDialog'),
            payload: programId,
        });
        dispatch({
            field: nameOf<PageModel>('programNameUpdateDialog'),
            payload: programName,
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateDialog'),
            payload: true,
        });
    }

    function onCloseUpdateDialog(): void {
        dispatch({
            field: nameOf<PageModel>('programIdUpdateDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('programNameUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateDialog'),
            payload: false,
        });
    }

    async function onClickUpdateButtonFromDialog(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ProgramService.updateProgram(state.programIdUpdateDialog, state.programNameUpdateDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success('Sikeres frissítés.');

            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseUpdateDialog();
    }

    function onClickRemoveButton(programId: number, programName: string): void {
        dispatch({
            field: nameOf<PageModel>('programIdRemoveDialog'),
            payload: programId,
        });
        dispatch({
            field: nameOf<PageModel>('programNameRemoveDialog'),
            payload: programName,
        });
        dispatch({
            field: nameOf<PageModel>('openRemoveDialog'),
            payload: true,
        });
    }

    function onCloseRemoveDialog(): void {
        dispatch({
            field: nameOf<PageModel>('programIdRemoveDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('programNameRemoveDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openRemoveDialog'),
            payload: false,
        });
    }

    async function onClickRemoveButtonFromDialog(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await ProgramService.removeProgram(state.programIdRemoveDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.success((response.data! as unknown) as string);

            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error(response.error!.message!);
        }

        onCloseRemoveDialog();
    }

    function onPageChange(event: unknown, newPage: number): void {
        dispatch({
            field: nameOf<PageModel>('page'),
            payload: newPage,
        });
    }

    function onRowsPerPageChange(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('rowsPerPage'),
            payload: (event.target.value as unknown) as number,
        });
        dispatch({
            field: nameOf<PageModel>('page'),
            payload: 0,
        });
    }

    function onClickBackdropToggle(): void {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: !state.backdropOpen,
        });
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Paper style={PaperStyles.filter}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                Szűrés
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Program létrehozás időbélyeg kezdet" onChange={onChangeProgramCreatedAtStartFilter} renderInput={DateTimePickerInput} value={state.programCreatedAtStartFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Program létrehozás időbélyeg vég" onChange={onChangeProgramCreatedAtEndFilter} renderInput={DateTimePickerInput} value={state.programCreatedAtEndFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Program módosítás időbélyeg kezdet" onChange={onChangeProgramUpdatedAtStartFilter} renderInput={DateTimePickerInput} value={state.programUpdatedAtStartFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Program módosítás időbélyeg vég" onChange={onChangeProgramUpdatedAtEndFilter} renderInput={DateTimePickerInput} value={state.programUpdatedAtEndFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Program azonosító" onChange={onChangeProgramIdFilter} style={FilterStyles.filter} type="number" value={state.programIdFilter} />
                                    </Grid>
                                )}
                                <Grid item md={3} sm={6} xs={12}>
                                    <Autocomplete autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeProgramNameFilter} options={state.programNames} renderInput={(params) => <TextField {...params} autoFocus label="Program megnevezés" style={FilterStyles.filter} />} value={state.programNameFilter} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos azonosító" onChange={onChangeUserIdFilter} style={FilterStyles.filter} type="number" value={state.userIdFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos felhasználónév" onChange={onChangeUserUsernameFilter} style={FilterStyles.filter} type="text" value={state.userUsernameFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos név" onChange={onChangeUserNameFilter} style={FilterStyles.filter} type="text" value={state.userNameFilter} />
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </LocalizationProvider>

            <Paper style={PaperStyles.main}>
                <TablePagination component="div" count={state.rowCount} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead sx={TableSx().row.title}>
                            <TableRow>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Program azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Program megnevezés
                                    </TableSortLabel>
                                </TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos felhasználónév
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos név
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Program létrehozási időbélyeg
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Program módosítási időbélyeg
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.programs.map(function (program: ProgramEntity, index: number): JSX.Element {
                                return (
                                    <TableRow key={program.id!} sx={index % 2 === 1 ? TableSx().row.odd : TableSx().row.even}>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{program.id!}</TableCell>
                                        )}
                                        <TableCell>{program.name!}</TableCell>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{program.user!.id!}</TableCell>
                                        )}
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell>{program.user!.username!}</TableCell>
                                        )}
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell>{program.user!.name!}</TableCell>
                                        )}
                                        <TableCell align="center">{moment(program.createdAt!).format(dateTimeFormat)}</TableCell>
                                        <TableCell align="center">{moment(program.updatedAt!).format(dateTimeFormat)}</TableCell>
                                        <TableCell align="center"><Fab aria-label="update" color="warning" onClick={() => onClickUpdateButton(program.id!, program.name!)} size="small"><Settings /></Fab></TableCell>
                                        <TableCell align="center"><Fab aria-label="remove" color="error" onClick={() => onClickRemoveButton(program.id!, program.name!)} size="small"><Delete /></Fab></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter sx={TableSx().row.title}>
                            <TableRow sx={TableSx().row.lastElement}>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Program azonosító</TableCell>
                                )}
                                <TableCell>Program megnevezés</TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Tulajdonos azonosító</TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell>Tulajdonos felhasználónév</TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell>Tulajdonos név</TableCell>
                                )}
                                <TableCell align="center">Program létrehozási időbélyeg</TableCell>
                                <TableCell align="center">Program módosítási időbélyeg</TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <TablePagination component="div" count={state.rowCount} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} style={TableSx().paginationBottom} />
            </Paper>

            <Fab aria-label="add" color="success" onClick={onClickAddButton} style={FabStyles.add}><Add /></Fab>

            <Dialog onClose={onCloseAddDialog} open={state.openAddDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Hozzáadás</DialogTitle>
                <DialogContent>
                    <TextField autoFocus fullWidth label="Megnevezés" onChange={onChangeProgramNameAddDialog} style={TextFieldStyles.dialog} type="text" value={state.programNameAddDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseAddDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="success" onClick={onClickAddButtonFromDialog} startIcon={<Add />} variant="contained">Hozzáadás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseUpdateDialog} open={state.openUpdateDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Frissítés</DialogTitle>
                <DialogContent>
                    <TextField autoFocus fullWidth label="Megnevezés" onChange={onChangeProgramNameUpdateDialog} style={TextFieldStyles.dialog} type="text" value={state.programNameUpdateDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseUpdateDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="warning" onClick={onClickUpdateButtonFromDialog} startIcon={<Settings />} variant="contained">Módosítás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseRemoveDialog} open={state.openRemoveDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Törlés</DialogTitle>
                <DialogContent>
                    <DialogContentText style={DialogStyles.remove}>Biztos törlöd a kijelölt programot: <br /><br />"{state.programNameRemoveDialog}"?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseRemoveDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="error" onClick={onClickRemoveButtonFromDialog} startIcon={<Delete />} variant="contained">Törlés</Button>
                </DialogActions>
            </Dialog>

            <Backdrop onClick={onClickBackdropToggle} open={state.backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </>
    );
}
