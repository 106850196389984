// -----------------------------------------------------------------Imports---
import {
    SxProps,
    useTheme,
} from '@mui/material';

import { CSSProperties } from 'react';

// -----------------------------------------------------------------Exports---
export const DialogStyles: any = {
    remove: {
        whiteSpace: 'pre-wrap',
    } as CSSProperties,
}

export const FabStyles: any = {
    add: {
        bottom: 25,
        position: 'fixed',
        right: 25,
    } as CSSProperties,
}

export const FilterStyles: any = {
    filter: {
        width: '100%',
    } as CSSProperties,
}

export const PaperStyles: any = {
    filter: {
        margin: '25px auto',
        padding: 5,
        width: '95%',
    } as CSSProperties,
    main: {
        margin: '25px auto',
        width: '95%',
    } as CSSProperties,
    mainToolbox: {
        margin: '25px auto',
        textAlign: 'right',
        padding: 5,
        width: '95%',
    } as CSSProperties,
}

export function TableSx(): any {
    return {
        cell: {
            footerAndHeader: {
                transition: '0.5s',
                '&:hover': {
                    backgroundColor: useTheme().palette.action.hover,
                    transition: '0.5s',
                },
            } as SxProps,
        } as any,
        paginationBottom: {
            marginBottom: 100,
        } as CSSProperties,
        row: {
            even: {
                backgroundColor: useTheme().palette.action.hover,
                transition: '0.5s',
                whiteSpace: 'pre-wrap',
                '&:hover': {
                    backgroundColor: 'slategray',
                    transition: '0.5s',
                },
            } as SxProps,
            lastElement: {
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            } as SxProps,
            odd: {
                transition: '0.5s',
                whiteSpace: 'pre-wrap',
                '&:hover': {
                    backgroundColor: 'slategray',
                    transition: '0.5s',
                },
            } as SxProps,
            title: {
                backgroundColor: useTheme().palette.common.black,
            } as SxProps,
        } as any,
    }
}

export const TextFieldStyles: any = {
    dialog: {
        marginBottom: 10,
        marginTop: 10,
    } as CSSProperties,
}
