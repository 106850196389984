// -----------------------------------------------------------------Imports---
import { CSSProperties } from 'react';

import {
    SxProps,
    useTheme,
} from '@mui/material';

// -----------------------------------------------------------------Exports---
export const AvatarStyles: any = {
    main: {
        height: 250,
        marginBottom: -125,
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: 125,
        width: 250,
    } as CSSProperties,
}

export const BoxStyles: any = {
    button: {
        marginTop: 25,
        textAlign: 'center',
    } as CSSProperties,
    main: {
        left: '50%',
        maxHeight: '100vh',
        overflow: 'hidden scroll',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    } as CSSProperties,
}

export function BoxSx(): any {
    return {
        main: {
            transition: '0.5s',
            width: '100%',
            [useTheme().breakpoints.up('md')]: {
                maxWidth: 750,
                transition: '0.5s',
                width: '50%',
            },
            [useTheme().breakpoints.up('sm')]: {
                maxWidth: 750,
                transition: '0.5s',
                width: '75%',
            },
        } as SxProps,
    }
}

export const ButtonStyles: any = {
    button: {
        marginLeft: 5,
        marginRight: 5,
        textTransform: 'none',
    } as CSSProperties,
}

export const CardStyles: any = {
    content: {
        marginTop: 125,
    } as CSSProperties,
}

export const DividerStyles: any = {
    main: {
        marginBottom: 10,
        marginTop: 10,
    } as CSSProperties,
}

export const GridStyles: any = {
    titleColumn: {
        textAlign: 'right',
        margin: 'auto 0',
    } as CSSProperties,
}

export const TextFieldStyles: any = {
    data: {
        width: '100%',
    } as CSSProperties,
}
