// -----------------------------------------------------------------Imports---
import { CSSProperties } from 'react';

import {
    SxProps,
    useTheme,
} from '@mui/material';

// -----------------------------------------------------------------Exports---
export const Images: any = {
    companyLogo: require('../../images/companyLogo.png'),
    productLogoWithIcon: require('../../images/productLogoWithIcon.png'),
}

export const BoxStyles: any = {
    main: {
        boxShadow: '0px 0px 25px black',
        maxHeight: '100vh',
        left: '50%',
        overflow: 'hidden scroll',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    } as CSSProperties,
}

export function BoxSx(): any {
    return {
        main: {
            transition: '0.5s',
            width: '100%',
            [useTheme().breakpoints.up('md')]: {
                maxWidth: 750,
                transition: '0.5s',
                width: '50%',
            },
            [useTheme().breakpoints.up('sm')]: {
                maxWidth: 750,
                transition: '0.5s',
                width: '75%',
            },
        } as SxProps,
    }
}

export const ButtonStyles: any = {
    login: {
        textTransform: 'none',
    } as CSSProperties,
}

export const CardMediaStyles: any = {
    header: {
        marginBottom: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
    } as CSSProperties,
}

export const CardStyles: any = {
    main: {
        padding: 25,
    } as CSSProperties,
}

export const DividerStyles: any = {
    footer: {
        marginBottom: 25,
        marginTop: 25,
    } as CSSProperties,
}

export const GridStyles: any = {
    left: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
        width: '50%',
    } as CSSProperties,
    main: {
        alignItems: 'center',
    } as CSSProperties,
    right: {
        textAlign: 'right',
        whiteSpace: 'nowrap',
        width: '50%',
    } as CSSProperties,
}

export const ImageStyles: any = {
    footer: {
        width: '50%',
    } as CSSProperties,
}

export function LinkStyles(): any {
    return {
        main: {
            color: useTheme().palette.common.white,
            textAlign: 'right',
            textDecoration: 'none',
            whiteSpace: 'nowrap',
        } as CSSProperties,
        footer: {
            textAlign: 'center',
        } as CSSProperties,
    }
}
