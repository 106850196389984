// -----------------------------------------------------------------Imports---
import {
    TextField,
    TextFieldProps,
} from '@mui/material';

import { TextFieldStyles } from './DateTimePickerInput.style';

// -----------------------------------------------------------------Exports---
export default function DateTimePickerInput(params: TextFieldProps): JSX.Element {
    return (
        <TextField {...params} style={TextFieldStyles.dateTimePicker} />
    );
}
