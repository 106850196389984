// -----------------------------------------------------------------Imports---
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models';

// ----------------------------------------------------------------Privates---
const isTest: boolean = true;

// -----------------------------------------------------------------Exports---
export const dateFormat: string = 'YYYY-MM-DD';
export const dateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';

export const dateTimePickerInputFormats: CalendarOrClockPickerView[] = ['year', 'month', 'day', 'hours', 'minutes', 'seconds'];

export const getAppApiRoutes: any = {
    apiKey: {
        addApiKey: '/apiKey' as string,
        getApiKeys: '/apiKey/getApiKeys' as string,
        updateApiKey: '/apiKey/{id}' as string,
        updateApiKeyRegenerate: '/apiKey/regenerate/{id}' as string,
        removeApiKey: '/apiKey/removeApiKey/{id}' as string,
    } as any,
    log: {
        addLog: '/log' as string,
        getLogs: '/log/getLogs' as string,
        updateLog: '/log/{id}' as string,
        removeLog: '/log/{id}' as string,
    } as any,
    login: '/authentication/login' as string,
    logLevel: {
        getLogLevels: '/logLevel/getLogLevels' as string,
    } as any,
    notificationSetting: {
        addNotificationSetting: '/notificationSetting' as string,
        getNotificationSettings: '/notificationSetting/getNotificationSettings' as string,
        updateNotificationSetting: '/notificationSetting/{id}' as string,
        removeNotificationSetting: '/notificationSetting/{id}' as string,
    } as any,
    program: {
        addProgram: '/program' as string,
        getPrograms: '/program/getPrograms' as string,
        updateProgram: '/program/{id}' as string,
        removeProgram: '/program/{id}' as string,
    } as any,
    statistics: {
        getAllStatistics: '/statistics' as string,
    } as any,
    user: {
        addUser: '/user' as string,
        getUser: '/user/{id}' as string,
        updateUser: '/user/{id}' as string,
        removeUser: '/user/removeUser/{id}' as string,
    } as any,
}

export function getAppApiUrlBase(): any {
    return isTest
        ? 'https://logger.api-test.szoftweb.com'
        : 'https://logger.api.szoftweb.com';
}

export const getAppRoutes: any = {
    about: '/about' as string,
    account: '/account' as string,
    apiKeys: '/apiKeys' as string,
    main: '/' as string,
    notFound: '*' as string,
    login: '/login' as string,
    logs: '/logs' as string,
    notifications: '/notifications' as string,
    programs: '/programs' as string,
    registration: '/registration' as string,
}

export const months: string[] = ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember', 'október', 'november', 'december'];

export type ReducerAction = {
    field: any,
    payload: any,
}
