// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class LogLevelService {
    async getLogLevels(): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.logLevel.getLogLevels,
                {
                    filterData: {

                    },
                    orderBy: 'value_asc',

                    //'id_asc'
                    //'id_desc'
                    //'createdAt_asc'
                    //'createdAt_desc'
                    //'updatedAt_asc'
                    //'updatedAt_desc'
                    //'name_asc'
                    //'name_desc'
                    //'value_asc'
                    //'value_desc'
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }
}

// -----------------------------------------------------------------Exports---
export default new LogLevelService();
