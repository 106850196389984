// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import { Moment } from 'moment';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class LogService {
    async getLogs(
        logId: string,
        logCreatedAtStart: Moment | null,
        logCreatedAtEnd: Moment | null,
        logUpdatedAtStart: Moment | null,
        logUpdatedAtEnd: Moment | null,
        programId: string,
        userId: string,
        userUsername: string,
        userEmail: string,
        userName: string,
        programName: string,
        logLevelId: string,
        logLevelName: string,
        logLevelValue: string,
        logMessage: string,
        logSubMessage: string,
        logVersion: string,
        page: number,
        rowsPerPage: number,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.log.getLogs,
                {
                    filterData: {
                        id: logId.length === 0 ? null : (logId as unknown) as number,
                        createdAtStart: logCreatedAtStart === null ? null : logCreatedAtStart.toISOString(),
                        createdAtEnd: logCreatedAtEnd === null ? null : logCreatedAtEnd.toISOString(),
                        updatedAtStart: logUpdatedAtStart === null ? null : logUpdatedAtStart.toISOString(),
                        updatedAtEnd: logUpdatedAtEnd === null ? null : logUpdatedAtEnd.toISOString(),
                        programId: programId.length === 0 ? null : (programId as unknown) as number,
                        userId: userId.length === 0 ? null : (userId as unknown) as number,
                        userUsername: userUsername.length === 0 ? null : userUsername,
                        userEmail: userEmail.length === 0 ? null : userEmail,
                        userName: userName.length === 0 ? null : userName,
                        programName: programName.length === 0 ? null : programName,
                        logLevelId: logLevelId.length === 0 ? null : (logLevelId as unknown) as number,
                        logLevelName: logLevelName.length === 0 ? null : logLevelName,
                        logLevelValue: logLevelValue.length === 0 ? null : (logLevelValue as unknown) as number,
                        message: logMessage.length === 0 ? null : logMessage,
                        subMessage: logSubMessage.length === 0 ? null : logSubMessage,
                        version: logVersion.length === 0 ? null : logVersion,
                    },
                    orderBy: 'createdAt_desc',
                    pagination: {
                        currentPage: page + 1,
                        dataPerPage: rowsPerPage,
                    },

                    // 'id_asc'
                    // 'id_desc'
                    // 'createdAt_asc'
                    // 'createdAt_desc'
                    // 'updatedAt_asc'
                    // 'updatedAt_desc'
                    // 'programId_asc'
                    // 'programId_desc'
                    // 'userId_asc'
                    // 'userId_desc'
                    // 'userUsername_asc'
                    // 'userUsername_desc'
                    // 'userEmail_asc'
                    // 'userEmail_desc'
                    // 'userName_asc'
                    // 'userName_desc'
                    // 'programName_asc'
                    // 'programName_desc'
                    // 'logLevelId_asc'
                    // 'logLevelId_desc'
                    // 'logLevelName_asc'
                    // 'logLevelName_desc'
                    // 'logLevelValue_asc'
                    // 'logLevelValue_desc'
                    // 'message_asc'
                    // 'message_desc'
                    // 'subMessage_asc'
                    // 'subMessage_desc'
                    // 'version_asc'
                    // 'version_desc'
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async addLog(
        programName: string,
        logLevelName: string,
        logMessage: string,
        logSubMessage: string,
        logVersion: string,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.log.addLog,
                {
                    programName: programName,
                    logLevelName: logLevelName,
                    message: logMessage,
                    subMessage: logSubMessage,
                    version: logVersion,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async updateLog(
        logId: number,
        programName: string,
        logLevelName: string,
        logMessage: string,
        logSubMessage: string,
        logVersion: string,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .put(
                getAppApiUrlBase() + getAppApiRoutes.log.updateLog.replace('{id}', (logId as unknown) as string),
                {
                    programName: programName,
                    logLevelName: logLevelName,
                    message: logMessage,
                    subMessage: logSubMessage,
                    version: logVersion,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async removeLog(logId: number): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .delete(
                getAppApiUrlBase() + getAppApiRoutes.log.removeLog.replace('{id}', (logId as unknown) as string),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }
}

// -----------------------------------------------------------------Exports---
export default new LogService();
