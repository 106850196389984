// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import { Moment } from 'moment';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class ApiKeyService {
    async getApiKeys(
        apiKeyId: string,
        apiKeyCreatedAtStart: Moment | null,
        apiKeyCreatedAtEnd: Moment | null,
        apiKeyUpdatedAtStart: Moment | null,
        apiKeyUpdatedAtEnd: Moment | null,
        userId: string,
        userUsername: string,
        userEmail: string,
        userName: string,
        apiKey: string,
        apiKeyName: string,
        apiKeyIsEnabled: string,
        page: number,
        rowsPerPage: number,
    ): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.apiKey.getApiKeys,
                {
                    filterData: {
                        id: apiKeyId.length === 0 ? null : (apiKeyId as unknown) as number,
                        createdAtStart: apiKeyCreatedAtStart === null ? null : apiKeyCreatedAtStart.toISOString(),
                        createdAtEnd: apiKeyCreatedAtEnd === null ? null : apiKeyCreatedAtEnd.toISOString(),
                        updatedAtStart: apiKeyUpdatedAtStart === null ? null : apiKeyUpdatedAtStart.toISOString(),
                        updatedAtEnd: apiKeyUpdatedAtEnd === null ? null : apiKeyUpdatedAtEnd.toISOString(),
                        userId: userId.length === 0 ? null : (userId as unknown) as number,
                        userUsername: userUsername.length === 0 ? null : userUsername,
                        userEmail: userEmail.length === 0 ? null : userEmail,
                        userName: userName.length === 0 ? null : userName,
                        apiKey: apiKey.length === 0 ? null : apiKey,
                        name: apiKeyName.length === 0 ? null : apiKeyName,
                        isEnabled: apiKeyIsEnabled.length === 0 ? null : apiKeyIsEnabled === 'aktív' ? true : false,
                    },
                    orderBy: 'name_asc',
                    pagination: {
                        currentPage: page + 1,
                        dataPerPage: rowsPerPage,
                    },

                    // 'id_asc'
                    // 'id_desc'
                    // 'createdAt_asc'
                    // 'createdAt_desc'
                    // 'updatedAt_asc'
                    // 'updatedAt_desc'
                    // 'userId_asc'
                    // 'userId_desc'
                    // 'userUsername_asc'
                    // 'userUsername_desc'
                    // 'userEmail_asc'
                    // 'userEmail_desc'
                    // 'userName_asc'
                    // 'userName_desc'
                    // 'apiKey_asc'
                    // 'apiKey_desc'
                    // 'name_asc'
                    // 'name_desc'
                    // 'enabled_asc'
                    // 'enabled_desc'
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async addApiKey(password: string, apiKeyName: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.apiKey.addApiKey,
                {
                    password: password,
                    name: apiKeyName,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async updateApiKey(apiKeyId: number, password: string, apiKeyName: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .put(
                getAppApiUrlBase() + getAppApiRoutes.apiKey.updateApiKey.replace('{id}', (apiKeyId as unknown) as string),
                {
                    password: password,
                    name: apiKeyName,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async updateApiKeyRegenerate(apiKeyId: number, password: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .put(
                getAppApiUrlBase() + getAppApiRoutes.apiKey.updateApiKeyRegenerate.replace('{id}', (apiKeyId as unknown) as string),
                {
                    password: password,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async removeApiKey(apiKeyId: number, password: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.apiKey.removeApiKey.replace('{id}', (apiKeyId as unknown) as string),
                {
                    password: password,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }
}

// -----------------------------------------------------------------Exports---
export default new ApiKeyService();
