// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import AuthenticationService from '../authentication/AuthenticationService';

import ResponseModel from '../../models/ResponseModel';

// ----------------------------------------------------------------Privates---
class UserService {
    async getUser(userId: number): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .get(
                getAppApiUrlBase() + getAppApiRoutes.user.getUser.replace('{id}', (userId as unknown) as string),
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async addUser(username: string, email: string, name: string, password: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.user.addUser,
                {
                    username: username,
                    email: email,
                    name: name,
                    password: password,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                responseData = (error.response! as any).data!;
            });

        return responseData;
    }

    async updateUser(userId: number, name: string, username: string, email: string, password: string, originalPassword: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .put(
                getAppApiUrlBase() + getAppApiRoutes.user.updateUser.replace('{id}', (userId as unknown) as string),
                {
                    originalPassword: originalPassword,
                    name: name,
                    username: username,
                    password: password.length === 0 ? null : password,
                    email: email,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    }
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
            })
            .catch(function (error: AxiosError): void {
                if (error.response!.status === 401) {
                    responseData = (error.response! as any);
                }
                else {
                    responseData = (error.response! as any).data!;
                }
            });

        return responseData;
    }

    async removeUser(userId: number, originalPassword: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.user.removeUser.replace('{id}', (userId as unknown) as string),
                {
                    originalPassword: originalPassword,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')!,
                    },
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
                AuthenticationService.logout();
            })
            .catch(function (error: AxiosError): void {
                responseData = (error.response! as any).data!;
            });

        return responseData;
    }
}

// -----------------------------------------------------------------Exports---
export default new UserService();
