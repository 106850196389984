// -----------------------------------------------------------------Imports---
import axios, { AxiosError } from 'axios';

import jwtDecode from 'jwt-decode';

import {
    getAppApiRoutes,
    getAppApiUrlBase,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import LoginResponseModel from '../../models/authentication/LoginResponseModel';

// ----------------------------------------------------------------Privates---
class AuthenticationService {
    async login(username: string, password: string): Promise<ResponseModel> {
        let responseData: ResponseModel = {
            status: 400,
            data: null,
            error: {
                message: 'Ismeretlen hiba történt...',
            },
        }

        await axios
            .post(
                getAppApiUrlBase() + getAppApiRoutes.login,
                {
                    username: username,
                    password: password,
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                }
            )
            .then(function (response: any): void {
                responseData = response.data;
                const loginResponseData: LoginResponseModel = responseData.data!;

                localStorage.setItem('token', loginResponseData.token!);
                localStorage.setItem('id', (loginResponseData.user!.id! as unknown) as string);
                localStorage.setItem('name', loginResponseData.user!.name!);
            })
            .catch(function (error: AxiosError): void {
                responseData = (error.response! as any).data!;
            });

        return responseData;
    }

    logout(): void {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
    }

    loggedInState(): boolean {
        if ((localStorage.getItem('token') === null) || (localStorage.getItem('token')?.length === 0)) {
            return false;
        }

        // TODO: Check local DateTime
        let expiration: Date = new Date(0);
        expiration.setUTCSeconds((jwtDecode(localStorage.getItem('token')!) as any).exp);

        if (expiration <= new Date()) {
            return false;
        }

        return true;
    }

    adminState(): boolean {
        return (jwtDecode(localStorage.getItem('token')!) as any).sub === '1';
    }
}

// -----------------------------------------------------------------Exports---
export default new AuthenticationService();
