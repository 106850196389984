// -----------------------------------------------------------------Imports---
import { CSSProperties } from 'react';

// -----------------------------------------------------------------Exports---
export const GridStyles: any = {
    card: {
        margin: 'auto',
        padding: 10,
    } as CSSProperties,
}

export const PaperStyles: any = {
    main: {
        margin: '25px auto',
        width: '95%',
    } as CSSProperties,
}
