// -----------------------------------------------------------------Imports---
import { CSSProperties } from 'react';

import {
    SxProps,
    useTheme,
} from '@mui/material';

// -----------------------------------------------------------------Exports---
export const AccordionStyle: any = {
    main: {
        boxShadow: 'none',
    } as CSSProperties,
}

export const CardStyles: any = {
    main: {
        boxShadow: '0px 0px 25px black',
        left: '50%',
        maxHeight: '100vh',
        overflow: 'hidden scroll',
        padding: 10,
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
    } as CSSProperties,
}

export function CardSx(): any {
    return {
        main: {
            transition: '0.5s',
            width: '100%',
            [useTheme().breakpoints.up('md')]: {
                maxWidth: 750,
                transition: '0.5s',
                width: '50%',
            },
            [useTheme().breakpoints.up('sm')]: {
                maxWidth: 750,
                transition: '0.5s',
                width: '75%',
            },
        } as SxProps,
    }
}

export const DividerStyles: any = {
    main: {
        marginBottom: 10,
        marginTop: 10,
    } as CSSProperties,
}

export const GridStyles: any = {
    left: {
        textAlign: 'left',
        margin: 'auto 0',
    } as CSSProperties,
    justify: {
        textAlign: 'justify',
        margin: 'auto 0',
    } as CSSProperties,
    right: {
        textAlign: 'right',
        margin: 'auto 0',
    } as CSSProperties,
}
