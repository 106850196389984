// -----------------------------------------------------------------Imports---
import { CSSProperties } from 'react';

// -----------------------------------------------------------------Exports---
export const BoxStyles: any = {
    main: {
        backgroundImage: 'linear-gradient(to top right, black, gray, slategray)',
        borderRadius: 10,
        padding: 10,
    } as CSSProperties,
}

export const GridStyles: any = {
    item: {
        margin: 'auto',
    } as CSSProperties,
}

export const TypographyStyles: any = {
    data: {
        fontSize: 30,
        fontWeight: 'bold',
    } as CSSProperties,
    subTitle: {
        fontSize: '90%',
        textAlign: 'right',
        whiteSpace: 'pre-wrap',
    } as CSSProperties,
    title: {
        marginTop: 10,
    } as CSSProperties,
}
