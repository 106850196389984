// -----------------------------------------------------------------Imports---
import moment from 'moment';

import {
    Dispatch,
    useEffect,
    useReducer,
} from 'react';

import { toast } from 'react-toastify';

import {
    Grid,
    Paper,
} from '@mui/material';

import {
    GridStyles,
    PaperStyles,
} from './HomePage.style';

import {
    nameOf,
    setReducer
} from '../../CodeKit';

import {
    dateFormat,
    months,
    ReducerAction,
} from '../../Global';

import StatisticsCard from '../../components/statisticsCard/StatisticsCard';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';
import AllStatisticsResponseModel from '../../models/statistics/AllStatisticsResponseModel';
import NumberOfDailyLogsResponseModel from '../../models/statistics/NumberOfDailyLogsResponseModel';
import NumberOfWeeklyLogsResponseModel from '../../models/statistics/NumberOfWeeklyLogsResponseModel';
import NumberOfMonthlyLogsResponseModel from '../../models/statistics/NumberOfMonthlyLogsResponseModel';
import NumberOfLogsPerYearResponseModel from '../../models/statistics/NumberOfLogsPerYearResponseModel';
import NumberOfAllLogsResponseModel from '../../models/statistics/NumberOfAllLogsResponseModel';
import NumberOfProgrammesResponseModel from '../../models/statistics/NumberOfProgrammesResponseModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import StatisticsService from '../../services/statistics/StatisticsService';

// ----------------------------------------------------------------Privates---
type PageModel = {
    allStatistics: AllStatisticsResponseModel;
    backdropOpen: boolean;
}

const initialState: PageModel = {
    allStatistics: {
        numberOfDailyLogs: {
            number: 0,
            today: new Date(),
        } as NumberOfDailyLogsResponseModel,
        numberOfWeeklyLogs: {
            number: 0,
            startDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1))),
            endDate: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + (new Date().getDay() === 0 ? -6 : 1) + 6)),
        } as NumberOfWeeklyLogsResponseModel,
        numberOfMonthlyLogs: {
            number: 0,
            month: new Date(),
        } as NumberOfMonthlyLogsResponseModel,
        numberOfLogsPerYear: {
            number: 0,
            year: new Date().getFullYear(),
        } as NumberOfLogsPerYearResponseModel,
        numberOfAllLogs: {
            number: 0,
        } as NumberOfAllLogsResponseModel,
        numberOfProgrammes: {
            number: 0,
        } as NumberOfProgrammesResponseModel,
    } as AllStatisticsResponseModel,
    backdropOpen: false,
}

// -----------------------------------------------------------------Exports---
export default function HomePage(): JSX.Element {
    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    const loggedInState: boolean = AuthenticationService.loggedInState();

    useEffect(function (): void {
        async function GetAllStatistics(): Promise<void> {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: true,
            });

            const response: ResponseModel = await StatisticsService.getAllStatistics();

            if (response.status === 200) {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: false,
                });
                dispatch({
                    field: nameOf<PageModel>('allStatistics'),
                    payload: response.data as AllStatisticsResponseModel,
                });
            }
            else if (response.status === 401) {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: false,
                });
                toast.error('Sikertelen autentikáció.');
            }
            else {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: false,
                });

                toast.error(response.error!.message!);

                if (response.error!.fields !== null) {
                    (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        if (loggedInState) {
            GetAllStatistics();
        }
        // eslint-disable-next-line
    }, []);

    // --------------------------------------------------------------Return---
    return (
        <>
            <Paper style={PaperStyles.main}>
                <Grid container>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <StatisticsCard data={state.allStatistics!.numberOfDailyLogs!.number as number} subTitle={moment(state.allStatistics!.numberOfDailyLogs!.today).format(dateFormat)} title="Mai logok száma" />
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <StatisticsCard data={state.allStatistics!.numberOfWeeklyLogs!.number as number} subTitle={moment(state.allStatistics!.numberOfWeeklyLogs!.startDate).format(dateFormat) + ' -\n- ' + moment(state.allStatistics!.numberOfWeeklyLogs!.endDate).format(dateFormat)} title="E heti logok száma" />
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <StatisticsCard data={state.allStatistics!.numberOfMonthlyLogs!.number as number} subTitle={moment(state.allStatistics!.numberOfMonthlyLogs!.month!).toDate().getFullYear().toString() + ' ' + months[moment(state.allStatistics!.numberOfMonthlyLogs!.month!).toDate().getMonth()]} title="E havi logok száma" />
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <StatisticsCard data={state.allStatistics!.numberOfLogsPerYear!.number as number} subTitle={state.allStatistics!.numberOfLogsPerYear!.year!.toString()} title="Ez évi logok száma" />
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <StatisticsCard data={state.allStatistics!.numberOfAllLogs!.number as number} title="Mindenkori logok száma" />
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <StatisticsCard data={state.allStatistics!.numberOfProgrammes!.number as number} title="Programok száma" />
                    </Grid>

                    {/* Diagramok */}
                    {/*
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <Box style={{
                            backgroundImage: 'linear-gradient(to top right, darkblue, blue, teal)',
                            borderRadius: 10,
                            textAlign: 'center',
                            padding: 10,
                        }}>
                            Mindenkori logok száma kördiagramban programokra bontva
                        </Box>
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <Box style={{
                            backgroundImage: 'linear-gradient(to top right, darkblue, blue, teal)',
                            borderRadius: 10,
                            textAlign: 'center',
                            padding: 10,
                        }}>
                            Mai logok száma programokra (azon belül szintekre) bontva grafikonban
                        </Box>
                    </Grid>
                    <Grid item style={GridStyles.card} md={4} sm={6} xs={12}>
                        <Box style={{
                            backgroundImage: 'linear-gradient(to top right, darkblue, blue, teal)',
                            borderRadius: 10,
                            textAlign: 'center',
                            padding: 10,
                        }}>
                            Aktuális heti logok száma napokra (azon belül programokra) bontva grafikonban
                        </Box>
                    </Grid>
                    <Grid item style={GridStyles.card} md={6} sm={6} xs={12}>
                        <Box style={{
                            backgroundImage: 'linear-gradient(to top right, darkblue, blue, teal)',
                            borderRadius: 10,
                            textAlign: 'center',
                            padding: 10,
                        }}>
                            Aktuális havi logok száma hetekre (azon belül programokra) bontva grafikonban
                        </Box>
                    </Grid>
                    <Grid item style={GridStyles.card} md={6} sm={6} xs={12}>
                        <Box style={{
                            backgroundImage: 'linear-gradient(to top right, darkblue, blue, teal)',
                            borderRadius: 10,
                            textAlign: 'center',
                            padding: 10,
                        }}>
                            Aktuális évi logok száma hónapokra (azon belül programokra) bontva grafikonban
                        </Box>
                    </Grid>
                    */}
                </Grid>
            </Paper>
        </>
    );
}
