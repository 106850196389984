// -----------------------------------------------------------------Imports---
import moment, { Moment } from 'moment';

import {
    ChangeEvent,
    Dispatch,
    useEffect,
    useReducer,
} from 'react';

import { toast } from 'react-toastify';

import { useDebounce } from 'use-debounce';

import {
    Add,
    Close,
    Delete,
    ExpandMore,
    Settings,
} from '@mui/icons-material';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Backdrop,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Typography,
} from '@mui/material';

import {
    DateTimePicker,
    LocalizationProvider,
} from '@mui/x-date-pickers';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import {
    DialogStyles,
    FabStyles,
    FilterStyles,
    PaperStyles,
    TableSx,
    TextFieldStyles,
} from './LogsPage.style';

import {
    logLevelColorFromLogLevel,
    momentIsValid,
    nameOf,
    numberInStringIsValid,
    setReducer,
} from '../../CodeKit';

import {
    dateTimeFormat,
    dateTimePickerInputFormats,
    ReducerAction,
} from '../../Global';

import DateTimePickerInput from '../../components/dateTimePickerInput/DateTimePickerInput';
import DialogTransition from '../../components/dialogTransition/DialogTransition';

import LogEntity from '../../entities/LogEntity';
import LogLevelEntity from '../../entities/LogLevelEntity';
import ProgramEntity from '../../entities/ProgramEntity';

import FieldValidationErrorModel from '../../models/FieldValidationErrorModel';
import ResponseModel from '../../models/ResponseModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';
import LogLevelService from '../../services/logLevel/LogLevelService';
import LogService from '../../services/log/LogService';
import ProgramService from '../../services/program/ProgramService';

// ----------------------------------------------------------------Privates---
type PageModel = {
    logs: LogEntity[];
    programNames: string[];
    logLevelNames: string[];
    backdropOpen: boolean;
    updateTable: boolean;
    logIdFilter: string;
    logCreatedAtStartFilter: Moment | null;
    logCreatedAtEndFilter: Moment | null;
    logUpdatedAtStartFilter: Moment | null;
    logUpdatedAtEndFilter: Moment | null;
    programIdFilter: string;
    userIdFilter: string;
    userUsernameFilter: string;
    userEmailFilter: string;
    userNameFilter: string;
    programNameFilter: string;
    logLevelIdFilter: string;
    logLevelNameFilter: string;
    logLevelValueFilter: string;
    logMessageFilter: string;
    logSubMessageFilter: string;
    logVersionFilter: string;
    openAddDialog: boolean;
    programNameAddDialog: string;
    logLevelNameAddDialog: string;
    logMessageAddDialog: string;
    logSubMessageAddDialog: string;
    logVersionAddDialog: string;
    openUpdateDialog: boolean;
    logIdUpdateDialog: number;
    programNameUpdateDialog: string;
    logLevelNameUpdateDialog: string;
    logMessageUpdateDialog: string;
    logSubMessageUpdateDialog: string;
    logVersionUpdateDialog: string;
    openRemoveDialog: boolean;
    logIdRemoveDialog: number;
    logMessageRemoveDialog: string;
    page: number;
    rowCount: number;
    rowsPerPage: number;
}

const initialState: PageModel = {
    logs: [],
    programNames: [],
    logLevelNames: [],
    backdropOpen: false,
    updateTable: false,
    logIdFilter: '',
    logCreatedAtStartFilter: null,
    logCreatedAtEndFilter: null,
    logUpdatedAtStartFilter: null,
    logUpdatedAtEndFilter: null,
    programIdFilter: '',
    userIdFilter: '',
    userUsernameFilter: '',
    userEmailFilter: '',
    userNameFilter: '',
    programNameFilter: '',
    logLevelIdFilter: '',
    logLevelNameFilter: '',
    logLevelValueFilter: '',
    logMessageFilter: '',
    logSubMessageFilter: '',
    logVersionFilter: '',
    openAddDialog: false,
    programNameAddDialog: '',
    logLevelNameAddDialog: '',
    logMessageAddDialog: '',
    logSubMessageAddDialog: '',
    logVersionAddDialog: '',
    openUpdateDialog: false,
    logIdUpdateDialog: -1,
    programNameUpdateDialog: '',
    logLevelNameUpdateDialog: '',
    logMessageUpdateDialog: '',
    logSubMessageUpdateDialog: '',
    logVersionUpdateDialog: '',
    openRemoveDialog: false,
    logIdRemoveDialog: -1,
    logMessageRemoveDialog: '',
    page: 0,
    rowCount: 0,
    rowsPerPage: 25,
}

// -----------------------------------------------------------------Exports---
export default function LogsPage(): JSX.Element {
    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    const [debouncedState] = useDebounce<PageModel>(state, 1000);

    useEffect(function (): void {
        async function GetPrograms(): Promise<void> {
            const responseProrams: ResponseModel = await ProgramService.getPrograms('', '', null, null, null, null, '', '', '', '', 0, 2147483647);

            if (responseProrams.status === 200) {
                let responseProgramNames: string[] = [];

                (responseProrams.data as ProgramEntity[]).map(function (program: ProgramEntity): void {
                    responseProgramNames.push(program.name!);
                    return undefined;
                });

                dispatch({
                    field: nameOf<PageModel>('programNames'),
                    payload: responseProgramNames,
                });
            }
            else if (responseProrams.status === 401) {
                toast.error('Sikertelen autentikáció.');
            }
            else {
                toast.error(responseProrams.error!.message!);

                if (responseProrams.error!.fields !== null) {
                    (responseProrams.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        async function GetLogLevels(): Promise<void> {
            const responseLogLevels: ResponseModel = await LogLevelService.getLogLevels();

            if (responseLogLevels.status === 200) {
                let responseLogLevelNames: string[] = [];

                (responseLogLevels.data as LogLevelEntity[]).map(function (logLevel: LogLevelEntity): void {
                    responseLogLevelNames.push(logLevel.name!);
                    return undefined;
                });

                dispatch({
                    field: nameOf<PageModel>('logLevelNames'),
                    payload: responseLogLevelNames,
                });
            }
            else if (responseLogLevels.status === 401) {
                toast.error('Sikertelen autentikáció.');
            }
            else {
                toast.error(responseLogLevels.error!.message!);

                if (responseLogLevels.error!.fields !== null) {
                    (responseLogLevels.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                        toast.error(field.field! + ': ' + field.message!);
                        return undefined;
                    });
                }
            }
        }

        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        GetPrograms();
        GetLogLevels();

        setInterval(function (): void {
            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }, 300000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
        function (): void {
            async function GetLogs(): Promise<void> {
                dispatch({
                    field: nameOf<PageModel>('backdropOpen'),
                    payload: true,
                });

                const response: ResponseModel = await LogService.getLogs(
                    debouncedState.logIdFilter,
                    debouncedState.logCreatedAtStartFilter,
                    debouncedState.logCreatedAtEndFilter,
                    debouncedState.logUpdatedAtStartFilter,
                    debouncedState.logUpdatedAtEndFilter,
                    debouncedState.programIdFilter,
                    debouncedState.userIdFilter,
                    debouncedState.userUsernameFilter,
                    debouncedState.userEmailFilter,
                    debouncedState.userNameFilter,
                    debouncedState.programNameFilter,
                    debouncedState.logLevelIdFilter,
                    debouncedState.logLevelNameFilter,
                    debouncedState.logLevelValueFilter,
                    debouncedState.logMessageFilter,
                    debouncedState.logSubMessageFilter,
                    debouncedState.logVersionFilter,
                    state.page,
                    state.rowsPerPage,
                );

                if (response.status === 200) {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });
                    dispatch({
                        field: nameOf<PageModel>('logs'),
                        payload: response.data as LogEntity[],
                    });
                    dispatch({
                        field: nameOf<PageModel>('rowCount'),
                        payload: response.pagination!.totalDataCount!,
                    });
                }
                else if (response.status === 401) {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });
                    toast.error('Sikertelen autentikáció.');
                }
                else {
                    dispatch({
                        field: nameOf<PageModel>('backdropOpen'),
                        payload: false,
                    });

                    toast.error(response.error!.message!);

                    if (response.error!.fields !== null) {
                        (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                            toast.error(field.field! + ': ' + field.message!);
                            return undefined;
                        });
                    }
                }
            }

            GetLogs();
        },
        [
            debouncedState.logIdFilter,
            debouncedState.logCreatedAtStartFilter,
            debouncedState.logCreatedAtEndFilter,
            debouncedState.logUpdatedAtStartFilter,
            debouncedState.logUpdatedAtEndFilter,
            debouncedState.programIdFilter,
            debouncedState.userIdFilter,
            debouncedState.userUsernameFilter,
            debouncedState.userEmailFilter,
            debouncedState.userNameFilter,
            debouncedState.programNameFilter,
            debouncedState.logLevelIdFilter,
            debouncedState.logLevelNameFilter,
            debouncedState.logLevelValueFilter,
            debouncedState.logMessageFilter,
            debouncedState.logSubMessageFilter,
            debouncedState.logVersionFilter,
            state.updateTable,
            state.page,
            state.rowsPerPage,
        ]
    );

    // --------------------------------------------------------------Events---
    function isOptionEqualToValueFromAutocomplete(option: string, value: string): boolean {
        return option === value;
    }

    function onClickInstantUpdate(): void {
        dispatch({
            field: nameOf<PageModel>('updateTable'),
            payload: !state.updateTable,
        });
    }

    function onChangeLogIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logIdFilter'),
            payload: event.target.value,
        });
    }

    function onChangeLogCreatedAtStartFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logCreatedAtStartFilter'),
            payload: value,
        });
    }

    function onChangeLogCreatedAtEndFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logCreatedAtEndFilter'),
            payload: value,
        });
    }

    function onChangeLogUpdatedAtStartFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logUpdatedAtStartFilter'),
            payload: value,
        });
    }

    function onChangeLogUpdatedAtEndFilter(value: Moment | null): void {
        if (!momentIsValid(value)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logUpdatedAtEndFilter'),
            payload: value,
        });
    }

    function onChangeProgramIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('programIdFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('userIdFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserUsernameFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userUsernameFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserEmailFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userEmailFilter'),
            payload: event.target.value,
        });
    }

    function onChangeUserNameFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('userNameFilter'),
            payload: event.target.value,
        });
    }

    function onInputChangeProgramNameFilter(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('programNameFilter'),
            payload: newValue,
        });
    }

    function onChangeLogLevelIdFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logLevelIdFilter'),
            payload: event.target.value,
        });
    }

    function onInputChangeLogLevelNameFilter(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('logLevelNameFilter'),
            payload: newValue,
        });
    }

    function onChangeLogLevelValueFilter(event: ChangeEvent<HTMLInputElement>): void {
        if (!numberInStringIsValid(event.target.value)) {
            return;
        }

        if ((event.target.value.length !== 0) && ((event.target.value as unknown) as number <= 0)) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logLevelValueFilter'),
            payload: event.target.value,
        });
    }

    function onChangeLogMessageFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logMessageFilter'),
            payload: event.target.value,
        });
    }

    function onChangeLogSubMessageFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logSubMessageFilter'),
            payload: event.target.value,
        });
    }

    function onChangeLogVersionFilter(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logVersionFilter'),
            payload: event.target.value,
        });
    }

    function onInputChangeProgramNameAddDialog(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('programNameAddDialog'),
            payload: newValue,
        });
    }

    function onChangeLogLevelNameAddDialog(event: any, newValue: string | null): void {
        if (newValue === null) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logLevelNameAddDialog'),
            payload: newValue,
        });
    }

    function onChangeLogMessageAddDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logMessageAddDialog'),
            payload: event.target.value,
        });
    }

    function onChangeLogSubMessageAddDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logSubMessageAddDialog'),
            payload: event.target.value,
        });
    }

    function onChangeLogVersionAddDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logVersionAddDialog'),
            payload: event.target.value,
        });
    }

    function onInputChangeProgramNameUpdateDialog(event: any, newValue: string): void {
        dispatch({
            field: nameOf<PageModel>('programNameUpdateDialog'),
            payload: newValue,
        });
    }

    function onChangeLogLevelNameUpdateDialog(event: any, newValue: string | null): void {
        if (newValue === null) {
            return;
        }

        dispatch({
            field: nameOf<PageModel>('logLevelNameUpdateDialog'),
            payload: newValue,
        });
    }

    function onChangeLogMessageUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logMessageUpdateDialog'),
            payload: event.target.value,
        });
    }

    function onChangeLogSubMessageUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logSubMessageUpdateDialog'),
            payload: event.target.value,
        });
    }

    function onChangeLogVersionUpdateDialog(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('logVersionUpdateDialog'),
            payload: event.target.value,
        });
    }

    function onClickAddButton(): void {
        dispatch({
            field: nameOf<PageModel>('openAddDialog'),
            payload: true,
        });
    }

    function onCloseAddDialog(): void {
        dispatch({
            field: nameOf<PageModel>('programNameAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logLevelNameAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logVersionAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logMessageAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logSubMessageAddDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openAddDialog'),
            payload: false,
        });
    }

    async function onClickAddButtonFromDialog(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await LogService.addLog(
            state.programNameAddDialog,
            state.logLevelNameAddDialog,
            state.logMessageAddDialog,
            state.logSubMessageAddDialog,
            state.logVersionAddDialog,
        );

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success('Sikeres hozzáadás.');
            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseAddDialog();
    }

    function onClickUpdateButton(
        logId: number,
        programName: string,
        logLevelName: string,
        logMessage: string,
        logSubMessage: string,
        logVersion: string,
    ): void {
        dispatch({
            field: nameOf<PageModel>('logIdUpdateDialog'),
            payload: logId,
        });
        dispatch({
            field: nameOf<PageModel>('programNameUpdateDialog'),
            payload: programName,
        });
        dispatch({
            field: nameOf<PageModel>('logLevelNameUpdateDialog'),
            payload: logLevelName,
        });
        dispatch({
            field: nameOf<PageModel>('logMessageUpdateDialog'),
            payload: logMessage,
        });
        dispatch({
            field: nameOf<PageModel>('logSubMessageUpdateDialog'),
            payload: logSubMessage,
        });
        dispatch({
            field: nameOf<PageModel>('logVersionUpdateDialog'),
            payload: logVersion,
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateDialog'),
            payload: true,
        });
    }

    function onCloseUpdateDialog(): void {
        dispatch({
            field: nameOf<PageModel>('logIdUpdateDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('programNameUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logLevelNameUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logMessageUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logSubMessageUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('logVersionUpdateDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openUpdateDialog'),
            payload: false,
        });
    }

    async function onClickUpdateButtonFromDialog(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await LogService.updateLog(
            state.logIdUpdateDialog,
            state.programNameUpdateDialog,
            state.logLevelNameUpdateDialog,
            state.logMessageUpdateDialog,
            state.logSubMessageUpdateDialog,
            state.logVersionUpdateDialog,
        );

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success('Sikeres frissítés.');
            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });

            toast.error(response.error!.message!);

            if (response.error!.fields !== null) {
                (response.error!.fields! as FieldValidationErrorModel[]).map(function (field: FieldValidationErrorModel): void {
                    toast.error(field.field! + ': ' + field.message!);
                    return undefined;
                });
            }
        }

        onCloseUpdateDialog();
    }

    function onClickRemoveButton(logId: number, logMessage: string): void {
        dispatch({
            field: nameOf<PageModel>('logIdRemoveDialog'),
            payload: logId,
        });
        dispatch({
            field: nameOf<PageModel>('logMessageRemoveDialog'),
            payload: logMessage,
        });
        dispatch({
            field: nameOf<PageModel>('openRemoveDialog'),
            payload: true,
        });
    }

    function onCloseRemoveDialog(): void {
        dispatch({
            field: nameOf<PageModel>('logIdRemoveDialog'),
            payload: -1,
        });
        dispatch({
            field: nameOf<PageModel>('logMessageRemoveDialog'),
            payload: '',
        });
        dispatch({
            field: nameOf<PageModel>('openRemoveDialog'),
            payload: false,
        });
    }

    async function onClickRemoveButtonFromDialog(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await LogService.removeLog(state.logIdRemoveDialog);

        if (response.status === 200) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success((response.data! as unknown) as string);
            dispatch({
                field: nameOf<PageModel>('updateTable'),
                payload: !state.updateTable,
            });
        }
        else if (response.status === 401) {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error('Sikertelen autentikáció.');
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error(response.error!.message!);
        }

        onCloseRemoveDialog();
    }

    function onPageChange(event: unknown, newPage: number): void {
        dispatch({
            field: nameOf<PageModel>('page'),
            payload: newPage,
        });
    }

    function onRowsPerPageChange(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('rowsPerPage'),
            payload: (event.target.value as unknown) as number,
        });
        dispatch({
            field: nameOf<PageModel>('page'),
            payload: 0,
        });
    }

    function onClickBackdropToggle(): void {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: !state.backdropOpen,
        });
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <Paper style={PaperStyles.mainToolbox}>
                <Button color="info" onClick={onClickInstantUpdate} variant="contained">
                    Azonnali frissítés
                </Button>
            </Paper>

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Paper style={PaperStyles.filter}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography>
                                Szűrés
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Log létrehozás időbélyeg kezdet" onChange={onChangeLogCreatedAtStartFilter} renderInput={DateTimePickerInput} value={state.logCreatedAtStartFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Log létrehozás időbélyeg vég" onChange={onChangeLogCreatedAtEndFilter} renderInput={DateTimePickerInput} value={state.logCreatedAtEndFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Log módosítás időbélyeg kezdet" onChange={onChangeLogUpdatedAtStartFilter} renderInput={DateTimePickerInput} value={state.logUpdatedAtStartFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <DateTimePicker ampm={false} inputFormat={dateTimeFormat} label="Log módosítás időbélyeg vég" onChange={onChangeLogUpdatedAtEndFilter} renderInput={DateTimePickerInput} value={state.logUpdatedAtEndFilter} views={dateTimePickerInputFormats} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Log azonosító" onChange={onChangeLogIdFilter} style={FilterStyles.filter} type="number" value={state.logIdFilter} />
                                    </Grid>
                                )}
                                <Grid item md={3} sm={6} xs={12}>
                                    <TextField label="Log üzenet" multiline onChange={onChangeLogMessageFilter} style={FilterStyles.filter} type="text" value={state.logMessageFilter} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <TextField label="Log alüzenet" multiline onChange={onChangeLogSubMessageFilter} style={FilterStyles.filter} type="text" value={state.logSubMessageFilter} />
                                </Grid>
                                <Grid item md={3} sm={6} xs={12}>
                                    <TextField label="Log verzió" onChange={onChangeLogVersionFilter} style={FilterStyles.filter} type="text" value={state.logVersionFilter} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Program azonosító" onChange={onChangeProgramIdFilter} style={FilterStyles.filter} type="number" value={state.programIdFilter} />
                                    </Grid>
                                )}
                                <Grid item md={3} sm={6} xs={12}>
                                    <Autocomplete autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeProgramNameFilter} options={state.programNames} renderInput={(params) => <TextField {...params} autoFocus label="Program megnevezés" style={FilterStyles.filter} />} value={state.programNameFilter} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Log szint azonosító" onChange={onChangeLogLevelIdFilter} style={FilterStyles.filter} type="number" value={state.logLevelIdFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Log szint érték" onChange={onChangeLogLevelValueFilter} style={FilterStyles.filter} type="number" value={state.logLevelValueFilter} />
                                    </Grid>
                                )}
                                <Grid item md={3} sm={6} xs={12}>
                                    <Autocomplete autoComplete disablePortal filterSelectedOptions isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeLogLevelNameFilter} options={state.logLevelNames} renderInput={(params) => <TextField {...params} label="Log szint megnevezés" style={FilterStyles.filter} />} value={state.logLevelNameFilter} />
                                </Grid>
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos azonosító" onChange={onChangeUserIdFilter} style={FilterStyles.filter} type="number" value={state.userIdFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos felhasználónév" onChange={onChangeUserUsernameFilter} style={FilterStyles.filter} type="text" value={state.userUsernameFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos név" onChange={onChangeUserNameFilter} style={FilterStyles.filter} type="text" value={state.userNameFilter} />
                                    </Grid>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <Grid item md={3} sm={6} xs={12}>
                                        <TextField label="Tulajdonos e-mail" onChange={onChangeUserEmailFilter} style={FilterStyles.filter} type="email" value={state.userEmailFilter} />
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Paper>
            </LocalizationProvider>

            <Paper style={PaperStyles.main}>
                <TablePagination component="div" count={state.rowCount} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} />

                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead sx={TableSx().row.title}>
                            <TableRow>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Log azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell align="justify" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Log üzenet
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="justify" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Log alüzenet
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Log verzió
                                    </TableSortLabel>
                                </TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Program azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Program megnevezés
                                    </TableSortLabel>
                                </TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Log szint azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Log szint megnevezés
                                    </TableSortLabel>
                                </TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right" sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos azonosító
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos felhasználónév
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell sx={TableSx().cell.footerAndHeader}>
                                        <TableSortLabel>
                                            Tulajdonos név
                                        </TableSortLabel>
                                    </TableCell>
                                )}
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Log létrehozási időbélyeg
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" sx={TableSx().cell.footerAndHeader}>
                                    <TableSortLabel>
                                        Log módosítási időbélyeg
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.logs.map(function (log: LogEntity, index: number): JSX.Element {
                                return (
                                    <TableRow key={log.id!} sx={index % 2 === 1 ? TableSx().row.odd : TableSx().row.even}>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{log.id!}</TableCell>
                                        )}
                                        <TableCell align="justify">{log.message!}</TableCell>
                                        <TableCell align="justify">{log.subMessage!}</TableCell>
                                        <TableCell>{log.version!}</TableCell>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{log.program!.id!}</TableCell>
                                        )}
                                        <TableCell>{log.program!.name!}</TableCell>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{log.logLevel!.logLevel!}</TableCell>
                                        )}
                                        <TableCell align="center"><Chip color={logLevelColorFromLogLevel(log.logLevel!.logLevel!)} label={log.logLevel!.name!} /></TableCell>
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell align="right">{log.program!.user!.id!}</TableCell>
                                        )}
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell>{log.program!.user!.username!}</TableCell>
                                        )}
                                        {(AuthenticationService.adminState()) && (
                                            <TableCell>{log.program!.user!.name!}</TableCell>
                                        )}
                                        <TableCell align="center">{moment(log.createdAt!).format(dateTimeFormat)}</TableCell>
                                        <TableCell align="center">{moment(log.updatedAt!).format(dateTimeFormat)}</TableCell>
                                        <TableCell align="center"><Fab aria-label="update" color="warning" onClick={() => onClickUpdateButton(log.id!, log.program!.name!, log.logLevel!.name!, log.message!, log.subMessage!, log.version!)} size="small"><Settings /></Fab></TableCell>
                                        <TableCell align="center"><Fab aria-label="remove" color="error" onClick={() => onClickRemoveButton(log.id!, log.message!)} size="small"><Delete /></Fab></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                        <TableFooter sx={TableSx().row.title}>
                            <TableRow sx={TableSx().row.lastElement}>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Log azonosító</TableCell>
                                )}
                                <TableCell align="justify">Log üzenet</TableCell>
                                <TableCell align="justify">Log alüzenet</TableCell>
                                <TableCell>Log verzió</TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Program azonosító</TableCell>
                                )}
                                <TableCell>Program megnevezés</TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Log szint azonosító</TableCell>
                                )}
                                <TableCell align="center">Log szint megnevezés</TableCell>
                                {(AuthenticationService.adminState()) && (
                                    <TableCell align="right">Tulajdonos azonosító</TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell>Tulajdonos felhasználónév</TableCell>
                                )}
                                {(AuthenticationService.adminState()) && (
                                    <TableCell>Tulajdonos név</TableCell>
                                )}
                                <TableCell align="center">Log létrehozási időbélyeg</TableCell>
                                <TableCell align="center">Log módosítási időbélyeg</TableCell>
                                <TableCell align="center" />
                                <TableCell align="center" />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>

                <TablePagination component="div" count={state.rowCount} onPageChange={onPageChange} onRowsPerPageChange={onRowsPerPageChange} page={state.page} rowsPerPage={state.rowsPerPage} rowsPerPageOptions={[25, 50, 100, 250, 500]} style={TableSx().paginationBottom} />
            </Paper>

            <Fab aria-label="add" color="success" onClick={onClickAddButton} style={FabStyles.add}><Add /></Fab>

            <Dialog onClose={onCloseAddDialog} open={state.openAddDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Hozzáadás</DialogTitle>
                <DialogContent>
                    <Autocomplete freeSolo autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeProgramNameAddDialog} options={state.programNames} renderInput={(params) => <TextField {...params} autoFocus label="Program megnevezés" style={TextFieldStyles.dialog} />} value={state.programNameAddDialog} />
                    <Autocomplete autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onChange={onChangeLogLevelNameAddDialog} options={state.logLevelNames} renderInput={(params) => <TextField {...params} label="Log szint megnevezés" style={TextFieldStyles.dialog} />} value={state.logLevelNameAddDialog} />
                    <TextField fullWidth label="Log üzenet" multiline onChange={onChangeLogMessageAddDialog} style={TextFieldStyles.dialog} type="text" value={state.logMessageAddDialog} />
                    <TextField fullWidth label="Log alüzenet" multiline onChange={onChangeLogSubMessageAddDialog} style={TextFieldStyles.dialog} type="text" value={state.logSubMessageAddDialog} />
                    <TextField fullWidth label="Log verzió" onChange={onChangeLogVersionAddDialog} style={TextFieldStyles.dialog} type="text" value={state.logVersionAddDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseAddDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="success" onClick={onClickAddButtonFromDialog} startIcon={<Add />} variant="contained">Hozzáadás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseUpdateDialog} open={state.openUpdateDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Frissítés</DialogTitle>
                <DialogContent>
                    <Autocomplete autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onInputChange={onInputChangeProgramNameUpdateDialog} options={state.programNames} renderInput={(params) => <TextField {...params} autoFocus label="Program megnevezés" style={TextFieldStyles.dialog} />} value={state.programNameUpdateDialog} />
                    <Autocomplete autoComplete disablePortal filterSelectedOptions fullWidth isOptionEqualToValue={isOptionEqualToValueFromAutocomplete} onChange={onChangeLogLevelNameUpdateDialog} options={state.logLevelNames} renderInput={(params) => <TextField {...params} label="Log szint megnevezés" style={TextFieldStyles.dialog} />} value={state.logLevelNameUpdateDialog} />
                    <TextField fullWidth label="Log üzenet" multiline onChange={onChangeLogMessageUpdateDialog} style={TextFieldStyles.dialog} type="text" value={state.logMessageUpdateDialog} />
                    <TextField fullWidth label="Log alüzenet" multiline onChange={onChangeLogSubMessageUpdateDialog} style={TextFieldStyles.dialog} type="text" value={state.logSubMessageUpdateDialog} />
                    <TextField fullWidth label="Log verzió" onChange={onChangeLogVersionUpdateDialog} style={TextFieldStyles.dialog} type="text" value={state.logVersionUpdateDialog} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseUpdateDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="warning" onClick={onClickUpdateButtonFromDialog} startIcon={<Settings />} variant="contained">Módosítás</Button>
                </DialogActions>
            </Dialog>

            <Dialog onClose={onCloseRemoveDialog} open={state.openRemoveDialog} TransitionComponent={DialogTransition}>
                <DialogTitle>Törlés</DialogTitle>
                <DialogContent>
                    <DialogContentText style={DialogStyles.remove}>Biztos törlöd a kijelölt log-ot: <br /><br />"{state.logMessageRemoveDialog}"?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseRemoveDialog} startIcon={<Close />} variant="outlined">Mégsem</Button>
                    <Button color="error" onClick={onClickRemoveButtonFromDialog} startIcon={<Delete />} variant="contained">Törlés</Button>
                </DialogActions>
            </Dialog>

            <Backdrop onClick={onClickBackdropToggle} open={state.backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </>
    );
}
