// -----------------------------------------------------------------Imports---
import { CSSProperties } from 'react';

import {
    PopoverOrigin,
    SxProps,
} from '@mui/material';

// -----------------------------------------------------------------Exports---
export const Images: any = {
    productLogoWithoutIcon: require('../../images/productLogoWithoutIcon.png'),
}

export const BoxSx: any = {
    extraSmall: {
        display: {
            md: 'none',
            xs: 'flex',
        },
        flexGrow: 1,
    } as SxProps,
    medium: {
        display: {
            md: 'flex',
            xs: 'none',
        },
        flexGrow: 1,
    } as SxProps,
    user: {
        flexGrow: 0,
    } as SxProps,
}

export const ButtonSx: any = {
    currentMenu: {
        backgroundColor: 'gray',
        color: 'cyan',
        display: 'block',
        my: 2,
    } as SxProps,
    menu: {
        color: 'white',
        display: 'block',
        my: 2,
    } as SxProps,
}

export const IconButtonSx: any = {
    user: {
        p: 0,
    } as SxProps,
}

export const ImageSx: any = {
    logoExtraSmall: {
        display: {
            md: 'none',
            xs: 'flex',
        },
        height: '25px',
        mr: 1,
    } as SxProps,
    logoMedium: {
        display: {
            md: 'flex',
            xs: 'none',
        },
        height: '25px',
        mr: 1,
    } as SxProps,
}

export const MenuOrigins: any = {
    extraSmallAnchor: {
        horizontal: 'left',
        vertical: 'bottom',
    } as PopoverOrigin,
    extraSmallTransform: {
        horizontal: 'left',
        vertical: 'top',
    } as PopoverOrigin,
    userAnchor: {
        horizontal: 'right',
        vertical: 'top',
    } as PopoverOrigin,
    userTransform: {
        horizontal: 'right',
        vertical: 'top',
    } as PopoverOrigin,
}

export const MenuItemStyles: any = {
    currentMenu: {
        backgroundColor: 'gray',
        color: 'cyan',
    } as CSSProperties,
    menu: {
        color: 'white',
    } as CSSProperties,
}

export const MenuSx: any = {
    extraSmall: {
        display: {
            md: 'none',
            xs: 'block',
        },
    } as SxProps,
    user: {
        mt: '45px',
    } as SxProps,
}
