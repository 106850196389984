// -----------------------------------------------------------------Imports---
import {
    ChangeEvent,
    createRef,
    Dispatch,
    KeyboardEvent,
    RefObject,
    useReducer,
} from 'react';

import {
    NavigateFunction,
    useNavigate,
} from 'react-router-dom';

import { toast } from 'react-toastify';

import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    CircularProgress,
    Divider,
    Grid,
    Link,
    Stack,
    TextField,
    Typography,
} from '@mui/material';

import {
    BoxStyles,
    BoxSx,
    ButtonStyles,
    CardMediaStyles,
    CardStyles,
    DividerStyles,
    GridStyles,
    Images,
    ImageStyles,
    LinkStyles,
} from './LoginPage.style';

import {
    nameOf,
    setReducer,
} from '../../CodeKit';

import {
    getAppRoutes,
    ReducerAction,
} from '../../Global';

import ResponseModel from '../../models/ResponseModel';
import LoginResponseModel from '../../models/authentication/LoginResponseModel';

import AuthenticationService from '../../services/authentication/AuthenticationService';

// ----------------------------------------------------------------Privates---
const inputIds: any = {
    username: 'inputUsername' as string,
    password: 'inputPassword' as string,
}

type PageModel = {
    backdropOpen: boolean;
    inputUsername: string;
    inputPassword: string;
}

const initialState: PageModel = {
    backdropOpen: false,
    inputUsername: '',
    inputPassword: '',
}

// -----------------------------------------------------------------Exports---
export default function Login(): JSX.Element {
    const navigate: NavigateFunction = useNavigate();

    const inputPasswordRef: RefObject<HTMLInputElement> = createRef<HTMLInputElement>();

    const [state, dispatch]: [PageModel, Dispatch<ReducerAction>] = useReducer(setReducer, initialState);

    // --------------------------------------------------------------Events---
    function onChangeInputUsername(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputUsername'),
            payload: event.target.value,
        });
    }

    function onChangeInputPassword(event: ChangeEvent<HTMLInputElement>): void {
        dispatch({
            field: nameOf<PageModel>('inputPassword'),
            payload: event.target.value,
        });
    }

    function onKeyPressInput(event: KeyboardEvent<HTMLImageElement>): void {
        if (event.key !== 'Enter') {
            return;
        }

        switch ((event.target as HTMLInputElement).id) {
            case inputIds.username: {
                const inputs: HTMLInputElement[] = Array.from(inputPasswordRef.current!.querySelectorAll('input')) || [];
                inputs[0] && inputs[0].focus();
                break;
            }
            case inputIds.password: {
                onClickLogin();
                break;
            }
        }
    }

    function onClickForgotPassword(): void {
        toast.warning('Ez a menüpont fejlesztés alatt áll.');
    }

    async function onClickLogin(): Promise<void> {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: true,
        });

        const response: ResponseModel = await AuthenticationService.login(state.inputUsername, state.inputPassword);

        if (response.status === 200) {
            const loginResponseData: LoginResponseModel = response.data!;
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.success('Üdvözöljük, ' + loginResponseData.user!.name! + '!');
            navigate(getAppRoutes.main);
        }
        else {
            dispatch({
                field: nameOf<PageModel>('backdropOpen'),
                payload: false,
            });
            toast.error(response.error!.message!);
        }
    }

    function onClickBackdropToggle(): void {
        dispatch({
            field: nameOf<PageModel>('backdropOpen'),
            payload: !state.backdropOpen,
        });
    }

    // --------------------------------------------------------------Return---
    return (
        <>
            <Box style={BoxStyles.main} sx={BoxSx().main}>
                <Card style={CardStyles.main}>
                    <CardMedia alt="productLogo" component="img" image={Images.productLogoWithIcon} style={CardMediaStyles.header} />
                    <Grid container style={GridStyles.main}>
                        <Grid item style={GridStyles.left}>
                            <CardHeader title="Bejelentkezés" />
                        </Grid>
                        <Grid item style={GridStyles.right}>
                            <Link href="/registration" style={LinkStyles().main}>
                                Nincs még fiókod?
                            </Link>
                        </Grid>
                    </Grid>
                    <CardContent>
                        <Stack spacing={1}>
                            <Typography>
                                Felhasználónév / E-mail cím
                            </Typography>
                            <TextField autoFocus id={inputIds.username} onChange={onChangeInputUsername} onKeyPress={onKeyPressInput} placeholder="felhasznalonev123 / email@domain.com" type="text" value={state.inputUsername} />
                            <Typography>
                                Jelszó
                            </Typography>
                            <TextField id={inputIds.password} onChange={onChangeInputPassword} onKeyPress={onKeyPressInput} placeholder="******" ref={inputPasswordRef} type="password" value={state.inputPassword} />
                            <Link href="#" onClick={onClickForgotPassword} style={LinkStyles().main}>
                                Elfelejtett jelszó
                            </Link>
                            <Button color="info" onClick={onClickLogin} style={ButtonStyles.login} type="submit" variant="contained">
                                Bejelentkezés
                            </Button>
                            <Divider style={DividerStyles.footer} variant="middle" />
                            <Link href="https://szoftweb.com/" style={LinkStyles().footer} target="_blank">
                                <Box alt="companyLogo" component="img" src={Images.companyLogo} style={ImageStyles.footer} />
                            </Link>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>

            <Backdrop onClick={onClickBackdropToggle} open={state.backdropOpen}>
                <CircularProgress />
            </Backdrop>
        </>
    );
}
