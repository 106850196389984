// -----------------------------------------------------------------Imports---
import {
    Location,
    useLocation,
} from 'react-router-dom';

import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Divider,
    Stack,
    Typography,
} from '@mui/material';

import {
    BoxStyles,
    BoxSx,
    CardMediaStyles,
    CardStyles,
    DividerStyles,
    Images,
    TypographyStyles,
} from './NotFoundPage.style';

// -----------------------------------------------------------------Exports---
export default function NotFoundPage(): JSX.Element {
    const location: Location = useLocation();

    // --------------------------------------------------------------Return---
    return (
        <Box style={BoxStyles.main} sx={BoxSx().main}>
            <Card style={CardStyles.main}>
                <CardMedia alt="errorLogo" component="img" image={Images.errorLogo} style={CardMediaStyles.header} />
                <Divider style={DividerStyles.header} variant="middle" />
                <CardHeader title="Hiba: 404" />
                <CardContent>
                    <Stack spacing={1}>
                        <Typography style={TypographyStyles.main}>
                            Figyelem! A keresett oldal ({location.pathname}) nem létezik!
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
}
