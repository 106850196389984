// -----------------------------------------------------------------Imports---
import moment, { Moment } from 'moment';

import { ReducerAction } from './Global';

// -----------------------------------------------------------------Exports---
export function logLevelColorFromLogLevel(logLevel: number): 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default' | undefined {
    switch (logLevel) {
        case 0: {
            return 'success';
        }
        case 1: {
            return 'primary';
        }
        case 2: {
            return 'info';
        }
        case 3: {
            return 'warning';
        }
        case 4: {
            return 'secondary';
        }
        case 5: {
            return 'error';
        }
        case 6: {
            return 'default';
        }
        default: {
            return undefined;
        }
    }
}

export function momentIsValid(value: Moment | null): boolean {
    return (value === null) || ((value !== null) && (moment(value).isValid()))
}

export function nameOf<T>(name: keyof T): string | number | symbol {
    return name;
}

export function numberInStringIsValid(value: string): boolean {
    return !isNaN((value as unknown) as number);
}

export function setReducer(state: any, action: ReducerAction): any {
    return {
        ...state,
        [action.field]: action.payload,
    };
}
